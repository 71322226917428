var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "task-replay-map-box" },
    [
      _c("div", { attrs: { id: "cesiumContainer_task-replay" } }),
      _c("div", { ref: "footer", staticClass: "footer" }, [
        _c("div", { staticClass: "play-controller" }, [
          _c("div", { style: "width:" + _vm.processingTextWidth + "px" }, [
            _vm._v(_vm._s(_vm.passedTime + "/" + _vm.totalTime)),
          ]),
          _c(
            "span",
            { staticClass: "video-play", on: { click: _vm.triggerPlay } },
            [
              _vm.loading
                ? _c("i", { staticClass: "el-icon-loading" })
                : _vm.play
                ? _c("i", { staticClass: "el-icon-video-pause" })
                : _c("i", { staticClass: "el-icon-video-play" }),
            ]
          ),
          _c(
            "div",
            {
              ref: "processing",
              staticClass: "processing",
              on: { click: _vm.setControllerPosition },
            },
            [
              _c("el-progress", {
                attrs: { percentage: _vm.percentage, "show-text": false },
              }),
              _c("div", {
                staticClass: "processing-controller",
                style: "left:" + _vm.percentage + "%",
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "tool-controller" }),
      ]),
      _vm.hangarShow
        ? _c("HangarDetails", {
            ref: "hangarDetails",
            attrs: {
              show: _vm.hangarShow,
              baseData: _vm.hangarBaseData,
              detailsData: _vm.hangarDetailsData,
              videoData: _vm.hangarVideoData,
            },
            on: {
              "update:show": function ($event) {
                _vm.hangarShow = $event
              },
              closeDetails: _vm.closeHangarPanel,
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "sider-content" }, [
        _c(
          "div",
          { staticClass: "video-source" },
          [
            _c(
              "el-select",
              {
                staticClass: "video-selected",
                attrs: { placeholder: _vm.$t("selectPlease") },
                on: { change: _vm.sourceChange },
                model: {
                  value: _vm.videoUrl,
                  callback: function ($$v) {
                    _vm.videoUrl = $$v
                  },
                  expression: "videoUrl",
                },
              },
              _vm._l(_vm.videoList, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "content-header" }, [
          _c("span", { staticClass: "content-title" }, [
            _vm._v(_vm._s(_vm.$t("UAVFootage"))),
          ]),
        ]),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "video-container" },
            [
              _c("xg-player", {
                ref: "videoLive",
                attrs: {
                  dialogData: _vm.dialogData,
                  videoUrl: _vm.videoUrl,
                  videoType: _vm.dialogData.videoType
                    ? _vm.dialogData.videoType
                    : "mp4",
                  screen: false,
                  controls: false,
                },
                on: {
                  showLive: true,
                  play: _vm.videoPlay,
                  pause: _vm.videoPause,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }