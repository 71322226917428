<template>
  <div class="task-replay-map-box">
    <div id="cesiumContainer_task-replay"></div>
    <!-- 进度条 -->
    <div
      ref="footer"
      class="footer">
      <div class="play-controller">
        <div :style="`width:${processingTextWidth}px`">{{ `${passedTime}/${totalTime}` }}</div>
        <span @click="triggerPlay" class="video-play">
          <i v-if="loading" class="el-icon-loading" ></i>
          <i v-else-if="play" class="el-icon-video-pause"></i>
          <i v-else class="el-icon-video-play"></i>
        </span>
        <div
          @click="setControllerPosition"
          ref="processing"
          class="processing">
          <el-progress
            :percentage="percentage"
            :show-text="false"
          ></el-progress>
          <div
            :style="`left:${percentage}%`"
            class="processing-controller"></div>
        </div>
      </div>
      <div class="tool-controller">
      </div>
    </div>
    <!-- 机库详情弹窗 -->
    <HangarDetails
      v-if="hangarShow"
      ref="hangarDetails"
      :show.sync="hangarShow"
      :baseData="hangarBaseData" 
      :detailsData="hangarDetailsData"
      :videoData="hangarVideoData"
      @closeDetails="closeHangarPanel" 
    />
    <!-- 视频容器 -->
    <div
      class="sider-content">
      <div
        class="video-source">
        <el-select 
          class="video-selected"
          v-model="videoUrl"
          @change="sourceChange"
          :placeholder="$t('selectPlease')">
          <el-option
            v-for="item in videoList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="content-header">
        <span class="content-title">{{$t('UAVFootage')}}</span>
      </div>
      <div class="content">
        <div class="video-container">
          <xg-player
            ref="videoLive"
            :dialogData="dialogData"
            :videoUrl="videoUrl"
            :videoType="dialogData.videoType? dialogData.videoType: 'mp4'"
            :screen="false"
            :controls="false"
            @showLive="true"
            @play="videoPlay"
            @pause="videoPause"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map3d from "../cesium/js/Map3d";
import  { mapGetters, mapActions } from 'vuex';
import BaseLayer from "@/components/cesium/js/BaseLayer";
import { getInspectionObjects } from "@/api/project/label";
import { getNoFlyZoneList } from '@/api/noFlyZone/index';
import { getAirportList } from '@/api/airportManager/index';
import { getHangarList } from '@/api/hangar/index';
// 加载巡检数据
import oilInspectionVue from '@/components/cesium/mixins/oilInspection.vue';
import noFlyZoneShowVue from "@/components/cesium/mixins/noFlyZoneShow.vue";
import airportShowVue from "@/components/cesium/mixins/airportShow.vue";
import hangarVue from "@/components/cesium/mixins/hangar.vue";
import hangarShowVue from "@/components/cesium/mixins/hangarShow.vue";
import NodeMap from '@/components/cesium/js/node/index';
import liveFlyRouteHook from '@/components/cesium/js/hooks/liveFlyRouteHook';
import xgPlayer from "@/components/video-live-xgplayer/index";

export default {
  name: "cesiumViewer",
  components: { xgPlayer },
  mixins: [ oilInspectionVue, noFlyZoneShowVue, airportShowVue, hangarVue, hangarShowVue ],
  props: {
    dialogData: {
      type: Object,
      default() {
        return {}
      }
    },
    // 视频源地址
    videoList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      // 轨迹是否在加载中
      loading: true,
      // 是否播放
      play: false,
      // 播放条显示进度
      percentage: 0,
      // 是否开启拖动
      controlDroping: false,
      // 实际播放进度
      propessControler: 0,
      // 当前i时间显示宽度
      processingTextWidth: 150,
      // 开始时间
      startTime: new Date().getTime(),
      // 结束时间
      endTime: new Date().getTime(),
      // 目前支持2d，3d以后再说 2d、3d 模式
      viewModel: Cesium.SceneMode.SCENE3D,
      // 视频地址
      videoUrl: this.videoList.length > 0? this.videoList[0].value: ""
    };
  },
  computed: {
    passedTime(){
      let total = this.endTime - this.startTime
      let current = this.percentage * total / 100
      let date = new Date(parseInt(current));
      let Hour = date.getHours() >= 8? date.getHours() - 8: 24 - date.getHours()
      let Minute = date.getMinutes()
      let Sechond = date.getSeconds()
      Hour = Hour < 10? '0' + Hour: Hour
      Minute = Minute < 10? '0' + Minute: Minute
      Sechond = Sechond < 10? '0' + Sechond: Sechond
      let  GMT =  (Hour === '00'?  '': Hour + ':') + Minute + ':' + Sechond;
      return GMT
    },
    totalTime(){
      let date = new Date(parseInt(this.endTime - this.startTime));
      let Hour = date.getHours() >= 8? date.getHours() - 8: 24 - date.getHours()
      let Minute = date.getMinutes()
      let Sechond = date.getSeconds()
      Hour = Hour < 10? '0' + Hour: Hour
      Minute = Minute < 10? '0' + Minute: Minute
      Sechond = Sechond < 10? '0' + Sechond: Sechond
      let  GMT =   Hour + ':' + Minute + ':' + Sechond;
      return GMT
    },
  },
  mounted() {
    this.$options.flyRouteHook = liveFlyRouteHook();
    this.initViewer();
    // 加载巡检对象
    this.$options.inspectionLayer = new BaseLayer({ name: "inspectionLayer" });
    this.$options.routeLayer = new BaseLayer({ name: "routeLayer" });
    this.$options.operationAreaLayer = new BaseLayer({ name: "operationAreaLayer" });
    this.$options.map3d.layerManager.add(this.$options.inspectionLayer);
    this.$options.map3d.layerManager.add(this.$options.routeLayer);
    this.$options.map3d.layerManager.add(this.$options.operationAreaLayer);
    this.requestInspectLabel(this.dialogData.projectId) // 加载巡检对象数据
    this.getNoFlyDataTwo(); // 加载禁飞区
    this.getAirportData(); // 加载机场
    this.getHangarData(this.dialogData); // 获取加载机库列表
    this.getRoute() //加载航线
    this.getOperationArea() //加载作业区
  },
  methods: {
    footerMouseUp(evt) {
      if (this.controlDroping) {
        this.controlDroping = false
      }
    },
    //设置进度条
    setControllerPosition(evt) {
      if (this.loading)  return
      this.loading = true
      var left = evt.clientX
      this.stopUpdateController()
      var footer = this.$refs.footer.getBoundingClientRect()
      // 鼠标相对footer的Dom元素的位置
      var leftFooter = left - footer.left
      // 鼠标相对进度条的位置
      var leftPro = leftFooter - 52 - this.processingTextWidth
      let percentage = 0
      if (leftPro < 0) {
        percentage = 0
      } else if (footer.width < leftFooter + 120 ) {
        percentage = 100
      } else {
        percentage = leftPro * 100 / (footer.width - 52 - this.processingTextWidth - 120) 
      }
      const duration = this.$refs.videoLive.player.duration
      const videoTime = percentage * duration / 100
      setTimeout(()=>{
        this.$refs.videoLive.player.currentTime = Math.round(videoTime)
        this.loading = false
        this.updateController()
      }, 50)
    },
    // 初始化页面
    initViewer() {
      this.$options.map3d = new Map3d("cesiumContainer_task-replay", {
        sceneMode: this.viewModel,
      });
      this.initLayer();
      this.setEditModel();
      if (this.$options.timer) {
        clearTimeout(this.$options.timer)
        this.$options.timer = null
      }
    },
    // 初始化图层
    initLayer() {
      this.$options.flyRouteHook.setMap({map3d: this.$options.map3d, flylineLayerID: "flylineLayer", 
        showVisualPolygon: true});
      this.initNoflyShowLayer();
      this.initAirportShowLayer();
      this.initHangarLayer();
    },
    // 开始和停止播放
    triggerPlay() {
      this.play = !this.play;
      if (this.play) {
        this.startPlay()
      } else {
        this.pausePlay()
      }
    },
    //开始播放
    startPlay() {
      this.$refs.videoLive.player.play()
      this.updateController()
    },
    // 暂停播放
    pausePlay(){
      this.$refs.videoLive.player.pause()
      if (this.$options.timer) {
        clearTimeout(this.$options.timer)
        this.$options.timer = null
      }
    },
    updateController() {
      let currentTime = this.$refs.videoLive.player.currentTime? this.$refs.videoLive.player.currentTime: 0
      const duration = this.$refs.videoLive.player.duration ? this.$refs.videoLive.player.duration : this.startTime
      this.endTime = this.startTime + duration * 1000
      if (currentTime > duration) {
        currentTime = duration
      }
      if (!duration) {
        this.percentage = 0
      } else {
        this.percentage = currentTime * 100 / duration
      }
      this.$options.timer = setTimeout(() => {
        this.updateController()
      }, 33);
    },
    stopUpdateController() {
      if (this.$options.timer) {
        clearTimeout(this.$options.timer)
        this.$options.timer = null
      }
    },
    videoPlay() {
      this.loading = false
      this.play = true
      const duration = this.$refs.videoLive.player.duration ? this.$refs.videoLive.player.duration : this.startTime
      this.endTime = this.startTime + duration * 1000
      this.startPlay()
    },
    videoPause() {
      this.play = false
    },
    // 清理图层
    clear() {
      this.$options.flyRouteHook.clearFlylineLayer();
    },
    // 加载巡检对象
    requestInspectLabel(id) {
      getInspectionObjects({
        projectId: id,
        businessType: 1
      }).then(res=>{
        const code = res.data.code
        if (code === 200) {
          // 给编辑图层集合添加图层
          res.data.data && this.addInspectLabels(res.data.data)
        }
      })
    },

    addInspectLabels (data) {
      this.createInspectionsIntoLayer(data, this.$options.inspectionLayer)
    },

    setEditModel(en = true) {
      // console.log('当前地图注册的交互事件：', en );
      const self = this;
      this.activeDraw = ''
      this.$options.map3d._container.style.cursor = "default";
      // 绘制完成后需要自动进入编辑模式 同时对部分元素
      let afterFinish =  (model, origin, selectFeature) => {
        !model && this.closeHangarPanel();
        const type = model && model.drawType ? model.drawType : undefined;
        switch (type) {
          case "hangarPoint":
            this.selectedHangarNode(model);
            break;
        }
      }
      // 注册完交互事件的对用回调
      this.$options.map3d.triggerEditModel(en, {
        map: this.$options.map3d,
        vueHandler: self,
        afterFinish
      });
    },
    // 切换视频来源
    sourceChange (url) {
      this.$refs.videoLive.switchURL(url)
    },
    // 加载航线
    getRoute() {
      if (!this.dialogData.routeLineGeom) return
      let coordinates = this.dialogData.routeLineGeom.coordinates
      var positions = []
      for (let k = 0; k < coordinates.length; k++) {
        const coordinate = coordinates[k];
        var position = Cesium.Cartesian3.fromDegrees(
          ...coordinate
        )
        positions.push(position)
      }
      let route = new NodeMap.Route({
        id: this.dialogData.id,
        name: this.dialogData.name,
        positions
      })
      this.$options.routeLayer.addNode(route)
    },
    // 加载作业区
    getOperationArea() {
      // 测试代码
      if (!this.dialogData.workAreaGeom) return
      let coordinates = this.dialogData.workAreaGeom.coordinates
      var positions = [];
      for (let k = 0; k < coordinates[0].length; k++) {
        const coordinate = coordinates[0][k];
        const position = Cesium.Cartesian3.fromDegrees(...coordinate);
        positions.push(position);
      }
      var name = this.dialogData.workAreaAttach.originalName
      var index = name.indexOf('.')
      name = name.slice(0, index > -1? index: name.length)
      const node = new NodeMap.OperationArea({
        id: this.dialogData.id,
        name: name,
        positions
      });
      this.$options.operationAreaLayer.addNode(node)
    }
  },
  beforeDestroy() {
    if(this.$options.timer){
      clearTimeout(this.$options.timer)
      this.$options.timer = null
    }
    this.$options.flyRouteHook && this.$options.flyRouteHook.clearFlyRoute();
    this.$options.flyRouteHook = null;
    this.$options.map3d && this.$options.map3d.destroy();
  },
};
</script>
<style lang="scss">
.task-replay-map-box{
  width: 100%;
  height: 100%;
  position: relative;
  #cesiumContainer_task-replay {
    width: 100%;
    height: 100%;
  }
  .footer {
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    z-index: 10;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    .play-controller {
      width: calc(100% - 100px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      .video-play {
        width: 20px;
        font-size: 20px;
        cursor: pointer;
      }
      .processing {
        cursor: pointer;
        margin-left: 10px;
        position: relative;
        width: calc(100% - 120px);
        position: relative;
        top: 1px;
        .processing-controller{
          cursor: pointer;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
          background-color: #fff;
          width: 16px;
          height: 16px;
          border-radius: 8px;
          
        }
      }
    }
    .tool-controller {
      width: 100px;
      display: flex;
      justify-content: end;
      .full {
        width: 16px;
        height: 16px;
      }
    }
    .el-progress-bar__inner{
      transform: none !important;
      -webkit-transition: none !important;
    }
  }
  .sider-content {
    color: #fff;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10000;
    min-width: 150px;
    box-sizing: border-box;
    .video-source {
      background: rgba(0, 0, 0, 0.8);
      margin-bottom: 10px;
      .video-selected {
        width: 100%;
        .el-input {
          .el-input__inner {
            background-color: transparent !important;
            border: 0px !important;
            color: #FFFFFF !important;
          }
        }
      }
    }
    .content-header {
      background: rgba(0, 0, 0, 0.8);
      font-size: 14px;
      font-weight: 600;
      position: relative;
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0px 10px;
      box-sizing: border-box;
      .content-title {
        border-bottom: 3px #409eff solid;
      }
      .close {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        cursor: pointer;
      }
    }
    .content {
      background-color: rgba(0, 0, 0, 0.8);
      ::v-deep .el-button--text {
        color: #265edd;
      }
      box-sizing: border-box;
      padding: 0px 10px 20px 10px;
      font-size: 12px;
      width: 100%;
      .video-container {
        width: 426px;
        height: 234px;
        position: relative;
        .video-menu {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 100001;
          padding: 4px;
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.6);
          img {
            width: 16px;
            height: 16px;
          }
          .update-btn {
            .span-text {
              background-color: #409eff;
              border-radius: 2px;
              color: white;
            }
            // color: #409EFF;
          }
        }
      }
    }
  }
}
</style>
