var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    { attrs: { id: "record-task-box" } },
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            "table-loading": _vm.loading,
            data: _vm.data,
            page: _vm.page,
            permission: _vm.permissionList,
          },
          on: {
            "update:page": function ($event) {
              _vm.page = $event
            },
            "row-del": _vm.rowDel,
            "search-change": _vm.searchChange,
            "search-reset": _vm.searchReset,
            "selection-change": _vm.selectionChange,
            "current-change": _vm.currentChange,
            "size-change": _vm.sizeChange,
            "refresh-change": _vm.refreshChange,
            "on-load": _vm.onLoad,
          },
          scopedSlots: _vm._u([
            {
              key: "mixedTime",
              fn: function (scope) {
                return [
                  _c("div", [
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.startTime) + " —— "),
                      ]),
                      _c("span", [_vm._v(_vm._s(scope.row.endTime))]),
                    ]),
                  ]),
                ]
              },
            },
            {
              key: "menu",
              fn: function (scope) {
                return [
                  _c(
                    "el-button",
                    {
                      ref: "RecordBtn" + scope.row.id,
                      attrs: {
                        type: "text",
                        size: "small",
                        icon: "el-icon-data-line",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showRecord(
                            scope.row,
                            "RecordBtn" + scope.row.id
                          )
                        },
                      },
                    },
                    [_vm._v("\n        任务回放\n      ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "text",
                        size: "small",
                        icon: "el-icon-edit",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.rowEdit(scope.row)
                        },
                      },
                    },
                    [_vm._v("\n        编辑\n      ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "text",
                        size: "small",
                        icon: "el-icon-files",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showFiles(scope.row)
                        },
                      },
                    },
                    [_vm._v("\n        视频文件\n      ")]
                  ),
                  _c(
                    "el-button",
                    {
                      ref: "removeBtn" + scope.row.id,
                      attrs: {
                        type: "text",
                        size: "small",
                        icon: "el-icon-delete",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.rowDel(
                            scope.row,
                            "removeBtn" + scope.row.id
                          )
                        },
                      },
                    },
                    [_vm._v("\n        删除\n      ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    icon: "el-icon-plus",
                    type: "primary",
                  },
                  on: { click: _vm.addTask },
                },
                [_vm._v(_vm._s(_vm.$t("btnAdd")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.action ? _vm.$t("addTask") : _vm.$t("editTask"),
            visible: _vm.editDialogVisible,
            width: "800px",
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.handleCloseEdit,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                model: _vm.editForm,
                rules: _vm.rules,
                "label-width": "auto",
                inline: "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("mission.name") + "：", prop: "name" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      size: "small",
                      placeholder: _vm.$t("inputPlease"),
                    },
                    model: {
                      value: _vm.editForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "name", $$v)
                      },
                      expression: "editForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("mission.businessType") + "：",
                    prop: "businessType",
                  },
                },
                [
                  _c("select-tree", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      data: _vm.businessTypeList,
                      size: "small",
                      placeholder: _vm.$t("selectPlease"),
                    },
                    model: {
                      value: _vm.editForm.businessType,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "businessType", $$v)
                      },
                      expression: "editForm.businessType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("mission.usingUAV") + "：",
                    prop: "equipmentId",
                  },
                },
                [
                  _c("select-tree", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      data: _vm.equipmentList,
                      size: "small",
                      placeholder: _vm.$t("selectPlease"),
                    },
                    model: {
                      value: _vm.editForm.equipmentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "equipmentId", $$v)
                      },
                      expression: "editForm.equipmentId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("mission.executionTime") + "：",
                    prop: "executionTime",
                  },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "396px" },
                    attrs: {
                      size: "small",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetimerange",
                      "range-separator": _vm.$t("to"),
                      "start-placeholder": _vm.$t("startTime"),
                      "end-placeholder": _vm.$t("endTime"),
                      "default-time": ["00:00:00", "23:59:59"],
                      placeholder: _vm.$t("selectPlease"),
                    },
                    model: {
                      value: _vm.editForm.executionTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "executionTime", $$v)
                      },
                      expression: "editForm.executionTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("notes") + "：", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "396px" },
                    attrs: {
                      type: "textarea",
                      size: "small",
                      placeholder: _vm.$t("inputPlease"),
                    },
                    model: {
                      value: _vm.editForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "remark", $$v)
                      },
                      expression: "editForm.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: _vm.$t("mission.uploadVideo") + "：" },
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticStyle: { width: "248px" },
                      attrs: {
                        headers: _vm.headers,
                        action: "/api/blade-resource/oss/endpoint/upload-file",
                        "on-remove": _vm.videoHandleRemove,
                        "before-remove": _vm.videoBeforeRemove,
                        multiple: "",
                        data: _vm.fileData,
                        name: "file",
                        limit: _vm.videoLimit,
                        "on-exceed": _vm.videoHandleExceed,
                        "on-success": _vm.videoHandleSuccess,
                        "before-upload": _vm.videoHandleBeforeUpload,
                        "on-error": _vm.videoHandleError,
                        "file-list": _vm.videoFileList,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v(_vm._s(_vm.$t("clickToUpload")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [_vm._v(_vm._s(_vm.$t("mission.uploadVideoDesc")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("mission.uploadRoute") + "：" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticStyle: { width: "248px" },
                      attrs: {
                        headers: _vm.headers,
                        action: "/api/blade-resource/oss/endpoint/upload-file",
                        data: _vm.fileData,
                        multiple: false,
                        "on-remove": _vm.RouteHandleRemove,
                        "before-remove": _vm.RouteBeforeRemove,
                        "on-exceed": _vm.RouteHandleExceed,
                        "on-success": _vm.RouteHandleSuccess,
                        "before-upload": _vm.RouteHandleBeforeUpload,
                        "file-list": _vm.routeFileList,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v(_vm._s(_vm.$t("clickToUpload")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [_vm._v(_vm._s(_vm.$t("mission.uploadRouteDesc")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("mission.uploadOperationArea") + "：",
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticStyle: { width: "248px" },
                      attrs: {
                        headers: _vm.headers,
                        action: "/api/blade-resource/oss/endpoint/upload-file",
                        multiple: false,
                        data: _vm.fileData,
                        "on-remove": _vm.operationAreaHandleRemove,
                        "before-remove": _vm.operationAreaBeforeRemove,
                        "on-success": _vm.operationAreaHandleSuccess,
                        "on-exceed": _vm.operationAreaHandleExceed,
                        "before-upload": _vm.operationAreaHandleBeforeUpload,
                        "file-list": _vm.workAreaFileList,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v(_vm._s(_vm.$t("clickToUpload")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("mission.uploadOperationAreaDesc"))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.handleCloseEdit },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("btnCancel")) + "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.submitLoading,
                      expression: "submitLoading",
                    },
                  ],
                  attrs: {
                    size: "small",
                    type: "primary",
                    disabled: _vm.count > 0,
                  },
                  on: { click: _vm.submitTask },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("btnOk")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.taskReplayVisible
        ? _c("task-replay-dialog", {
            attrs: {
              videoList: _vm.videoForMapList,
              dialogData: _vm.dialogData,
              dialogVisible: _vm.taskReplayVisible,
            },
            on: { closeDialog: _vm.closetaskReplayVisible },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("videoFile"),
            visible: _vm.videoDialogVisible,
            width: "400px",
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.handleCloseVideo,
          },
          on: {
            "update:visible": function ($event) {
              _vm.videoDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-checkbox",
                {
                  attrs: { indeterminate: _vm.isIndeterminate },
                  on: { change: _vm.handleCheckAllChange },
                  model: {
                    value: _vm.checkFileAll,
                    callback: function ($$v) {
                      _vm.checkFileAll = $$v
                    },
                    expression: "checkFileAll",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("checkAll")))]
              ),
              _c("div", { staticStyle: { margin: "30px 0" } }),
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.handleCheckedCitiesChange },
                  model: {
                    value: _vm.checkedFileList,
                    callback: function ($$v) {
                      _vm.checkedFileList = $$v
                    },
                    expression: "checkedFileList",
                  },
                },
                _vm._l(_vm.videoLoadingFileList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.url,
                      staticClass: "record-task-file-checkbox-item",
                      staticStyle: { "margin-bottom": "10px" },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: item.url } }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _c("i", {
                        staticClass:
                          "icon file-checkbox-item-icon el-icon-download",
                        on: {
                          click: function ($event) {
                            return _vm.downloadFile(item)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.handleCloseVideo },
                },
                [_vm._v(_vm._s(_vm.$t("closeText")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.downLoadAllFile },
                },
                [_vm._v(_vm._s(_vm.$t("downLoadAll")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }