var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    { attrs: { id: "hanger-task-box-01" } },
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            "table-loading": _vm.loading,
            data: _vm.data,
            page: _vm.page,
            permission: _vm.permissionList,
          },
          on: {
            "update:page": function ($event) {
              _vm.page = $event
            },
            "row-update": _vm.rowUpdate,
            "row-save": _vm.rowSave,
            "row-del": _vm.rowDel,
            "search-change": _vm.searchChange,
            "search-reset": _vm.searchReset,
            "selection-change": _vm.selectionChange,
            "current-change": _vm.currentChange,
            "size-change": _vm.sizeChange,
            "refresh-change": _vm.refreshChange,
            "on-load": _vm.onLoad,
          },
          scopedSlots: _vm._u([
            {
              key: "uavId",
              fn: function (ref) {
                var row = ref.row
                return [_c("span", [_vm._v(_vm._s(row.airPlaneName || "-"))])]
              },
            },
            {
              key: "alarmState",
              fn: function (ref) {
                var row = ref.row
                return [
                  row.alarmState
                    ? _c("div", { staticClass: "abnormalCondition" }, [
                        _vm._v("有"),
                      ])
                    : _c("div", [_vm._v("无")]),
                ]
              },
            },
            {
              key: "mixedTime",
              fn: function (scope) {
                return [
                  _c("div", [
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.planBeginTime) + " —— "),
                      ]),
                      _c("span", [_vm._v(_vm._s(scope.row.planFinishTime))]),
                    ]),
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.realBeginTime) + " —— "),
                      ]),
                      _c("span", [_vm._v(_vm._s(scope.row.realFinishTime))]),
                    ]),
                  ]),
                ]
              },
            },
            {
              key: "hangarTaskType",
              fn: function (scope) {
                return [
                  _c("div", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.hangarTaskTypeList.find(function (item) {
                            return scope.row.hangarTaskType === item.value
                          }).label
                        ) +
                        "\n      "
                    ),
                  ]),
                ]
              },
            },
            {
              key: "menu",
              fn: function (scope) {
                return [
                  _vm.arbitrament("view", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-view",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.viewDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        查看\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("endTask", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-s-release",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.endTask(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        结束任务\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("cancelTask", scope.row)
                    ? _c(
                        "el-button",
                        {
                          ref: "cancelTaskBtn" + scope.row.id,
                          attrs: {
                            type: "text",
                            icon: "el-icon-error",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.cancelTask(
                                scope.row,
                                "cancelTaskBtn" + scope.row.id
                              )
                            },
                          },
                        },
                        [_vm._v("\n        取消任务\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("videoReplay", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-video-camera-solid",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.viewVideoDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        视频回放\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("trackReplay", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-discover",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.viewDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        轨迹回放\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("download", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-download",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.downLoadReport(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        下载报告\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("del", scope.row)
                    ? _c(
                        "el-button",
                        {
                          ref: "removeBtn" + scope.row.id,
                          attrs: {
                            type: "text",
                            icon: "el-icon-delete",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.rowDel(
                                scope.row,
                                "removeBtn" + scope.row.id
                              )
                            },
                          },
                        },
                        [_vm._v("\n        删除\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("stream", scope.row)
                    ? _c(
                        "el-dropdown",
                        {
                          staticClass: "copy-btn",
                          staticStyle: { display: "block" },
                          attrs: { placement: "bottom" },
                          on: { command: _vm.onCopy },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "copy-btn" },
                            [
                              _c(
                                "el-button",
                                { attrs: { type: "text", size: "small" } },
                                [_vm._v(" 复制推流地址 ")]
                              ),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down task-detail-drodown",
                              }),
                            ],
                            1
                          ),
                          (
                            scope.row.liveVideoVOList &&
                            Array.isArray(scope.row.liveVideoVOList)
                              ? true
                              : false
                          )
                            ? _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(
                                  scope.row.liveVideoVOList
                                    ? scope.row.liveVideoVOList
                                    : [],
                                  function (item) {
                                    return _c(
                                      "el-dropdown-item",
                                      {
                                        key: item.id,
                                        attrs: { command: [item, "push"] },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(item.liveDevice) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arbitrament("stream", scope.row)
                    ? _c(
                        "el-dropdown",
                        {
                          staticClass: "copy-btn",
                          staticStyle: { display: "block" },
                          attrs: { placement: "bottom" },
                          on: { command: _vm.onCopy },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "copy-btn" },
                            [
                              _c(
                                "el-button",
                                { attrs: { type: "text", size: "small" } },
                                [_vm._v(" 复制播放地址 ")]
                              ),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down task-detail-drodown",
                              }),
                            ],
                            1
                          ),
                          (
                            scope.row.liveVideoVOList &&
                            Array.isArray(scope.row.liveVideoVOList)
                              ? true
                              : false
                          )
                            ? _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(
                                  scope.row.liveVideoVOList
                                    ? scope.row.liveVideoVOList
                                    : [],
                                  function (item) {
                                    return _c(
                                      "el-dropdown-item",
                                      {
                                        key: item.id,
                                        attrs: { command: [item, "pull"] },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(item.liveDevice) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arbitrament("execute", scope.row)
                    ? _c(
                        "el-button",
                        {
                          ref: "executeTask" + scope.row.id,
                          attrs: {
                            type: "text",
                            icon: "el-icon-position",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.executeTask(
                                scope.row,
                                "executeTask" + scope.row.id
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("execute")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "taskStatusName",
              fn: function (scope) {
                return [
                  _c(
                    "el-tag",
                    {
                      attrs: {
                        type:
                          scope.row.taskStatus == 3
                            ? "success"
                            : scope.row.taskStatus == 4
                            ? ""
                            : scope.row.taskStatus == 5
                            ? "warning"
                            : scope.row.taskStatus == 6
                            ? "danger"
                            : "info",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.statusMap[scope.row.taskStatus]) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    icon: "el-icon-plus",
                    type: "primary",
                  },
                  on: { click: _vm.addTask },
                },
                [_vm._v(_vm._s(_vm.$t("btnAdd")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm.detailVisible
        ? _c("DetailDialog", {
            attrs: {
              dialogData: _vm.dialogData,
              dialogVisible: _vm.detailVisible,
            },
            on: { closeDialog: _vm.closeDetail },
          })
        : _vm._e(),
      _vm.videoHistoryDialogVisible
        ? _c("VideoDialog", {
            attrs: {
              taskPlayBackData: _vm.taskPlayBackData,
              videoDialogData: _vm.videoDialogData,
              videoDialogVisible: _vm.videoHistoryDialogVisible,
            },
            on: { videoCloseDialog: _vm.videoCloseDialog },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("addTask"),
            visible: _vm.editDialogVisible,
            width: "900px",
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.handleCloseEdit,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.editForm,
                rules: _vm.rules,
                "label-width": "auto",
                inline: "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("mission.name") + "：",
                    prop: "taskName",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      size: "small",
                      placeholder: _vm.$t("inputPlease"),
                    },
                    model: {
                      value: _vm.editForm.taskName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "taskName", $$v)
                      },
                      expression: "editForm.taskName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("mission.brand") + "：",
                    prop: "manufacturerId",
                  },
                },
                [
                  _c("select-tree", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      data: _vm.manufacturerList,
                      size: "small",
                      clearable: true,
                      placeholder: _vm.$t("selectPlease"),
                    },
                    on: {
                      clear: _vm.manufacturerClear,
                      change: _vm.manufacturerChange,
                    },
                    model: {
                      value: _vm.editForm.manufacturerId,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "manufacturerId", $$v)
                      },
                      expression: "editForm.manufacturerId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("mission.usingHangar") + "：",
                    prop: "hangarID",
                  },
                },
                [
                  _c("select-tree", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      data: _vm.hangarList,
                      size: "small",
                      clearable: true,
                      disabled: !_vm.editForm.manufacturerId,
                      placeholder: _vm.$t("selectPlease"),
                    },
                    on: { clear: _vm.hangerClear, change: _vm.hangerChange },
                    model: {
                      value: _vm.editForm.hangarID,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "hangarID", $$v)
                      },
                      expression: "editForm.hangarID",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("mission.usingRoute") + "：",
                    prop: "routeId",
                  },
                },
                [
                  _c("select-tree", {
                    staticStyle: { width: "178px" },
                    attrs: {
                      data: _vm.taskRouteList,
                      clearable: true,
                      disabled: !_vm.editForm.hangarID,
                      size: "small",
                      placeholder: _vm.$t("selectPlease"),
                    },
                    model: {
                      value: _vm.editForm.routeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "routeId", $$v)
                      },
                      expression: "editForm.routeId",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "5px", padding: "8px 7px" },
                      attrs: {
                        type: "primary",
                        icon: "el-icon-view",
                        disabled: !_vm.editForm.hangarID,
                        size: "small",
                      },
                      on: { click: _vm.showRoute },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("mission.viewRoute")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("mission.hangarTaskType") + "：",
                    prop: "hangarTaskType",
                  },
                },
                [
                  _c("select-tree", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      disabled: true,
                      showSearch: false,
                      data: _vm.hangarTaskTypeList,
                      size: "small",
                      placeholder: _vm.$t("selectPlease"),
                    },
                    model: {
                      value: _vm.editForm.hangarTaskType,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "hangarTaskType", $$v)
                      },
                      expression: "editForm.hangarTaskType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("mission.executionTime") + "：",
                    prop: "executionTime",
                  },
                },
                [
                  _c("el-time-picker", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      size: "small",
                      "value-format": "HH:mm:ss",
                      placeholder: _vm.$t("selectPlease"),
                    },
                    model: {
                      value: _vm.editForm.executionTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "executionTime", $$v)
                      },
                      expression: "editForm.executionTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("mission.taskCycle") + "：",
                    prop: "scheduleTimeList",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "178px" },
                    attrs: {
                      disabled: true,
                      size: "small",
                      placeholder: _vm.$t("mission.cycleByClick"),
                    },
                    model: {
                      value: _vm.editForm.scheduleTimeList,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "scheduleTimeList", $$v)
                      },
                      expression: "editForm.scheduleTimeList",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "5px", padding: "8px 7px" },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.openCalendar },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("mission.choseDate")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("mission.usingUAV") + "：",
                    prop: "equipmentName",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      disabled: true,
                      size: "small",
                      placeholder: _vm.$t("inputPlease"),
                    },
                    model: {
                      value: _vm.editForm.equipmentName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "equipmentName", $$v)
                      },
                      expression: "editForm.equipmentName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.handleCloseEdit },
                },
                [_vm._v(_vm._s(_vm.$t("btnCancel")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.submitLoading,
                      expression: "submitLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submitTask },
                },
                [_vm._v(_vm._s(_vm.$t("btnOk")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("mission.chooseTaskCycle"),
            visible: _vm.calendarDialogVisible,
            width: "900px",
            top: "30px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.calendarDialogVisible = $event
            },
          },
        },
        [
          _c("el-calendar", {
            scopedSlots: _vm._u([
              {
                key: "dateCell",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "calendar-cell",
                        staticStyle: { height: "100%" },
                        on: {
                          click: function ($event) {
                            return _vm.selectedDate(data)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "calendar-cell-title" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(data.day.split("-")[2]) +
                              "\n            " +
                              _vm._s(
                                _vm.editForm.scheduleTimeList.indexOf(
                                  data.day
                                ) > -1
                                  ? "✔️"
                                  : ""
                              ) +
                              "\n          "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "calendar-cell-context" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.calendarContext(data),
                                  placement: "right",
                                },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.calendarContext(data)) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.clearDate } },
                [_vm._v(_vm._s(_vm.$t("emptyBtnText")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submitCalendar },
                },
                [_vm._v(_vm._s(_vm.$t("btnOk")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("hangar-route-dialog", {
        attrs: {
          routeList: _vm.taskRouteList,
          dialogVisible: _vm.hangarRouteDialogVisible,
        },
        on: {
          handleClose: _vm.hangarRouteDialogClose,
          submit: _vm.chooseHangarRoute,
          "update:dialogVisible": function ($event) {
            _vm.hangarRouteDialogVisible = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.hangarRouteDialogVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }