var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "task-replay-dialog-main",
      attrs: {
        title: _vm.$t("mission.MissionDetails"),
        visible: _vm.dialogVisible,
        fullscreen: true,
        "append-to-body": true,
        "before-close": _vm.closeDialog,
        "destroy-on-close": "",
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.dialogVisible
        ? _c("div", { staticClass: "task-fly" }, [
            _c("div", { staticClass: "task-info" }, [
              _c("div", { staticClass: "task-info-title" }, [
                _c("i", {
                  staticClass: "el-icon-arrow-left",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.closeDialog },
                }),
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("mission.taskReplay")) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "task-info-content" }, [
                _c("div", { staticClass: "task-info-content-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("mission.MissionDetails")) +
                      "\n        "
                  ),
                ]),
                _c("div", { staticClass: "task-info-content-box" }, [
                  _c("div", { staticClass: "item-line" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("mission.name")) + ":")]),
                    _c("span", [_vm._v(_vm._s(_vm.dialogData.name))]),
                  ]),
                  _c("div", { staticClass: "item-line" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("mission.taskType")) + ":"),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.dialogData.taskType))]),
                  ]),
                  _c("div", { staticClass: "item-line" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("mission.aerocraft")) + ":"),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.dialogData.equipmentName))]),
                  ]),
                  _c("div", { staticClass: "item-line" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("startTime")) + ":")]),
                    _c("span", [_vm._v(_vm._s(_vm.dialogData.startTime))]),
                  ]),
                  _c("div", { staticClass: "item-line" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("endTime")) + ":")]),
                    _c("span", [_vm._v(_vm._s(_vm.dialogData.endTime))]),
                  ]),
                  _c("div", { staticClass: "item-line" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("mission.team")) + ":")]),
                    _c("span", [_vm._v(_vm._s(_vm.dialogData.deptNames))]),
                  ]),
                  _c("div", { staticClass: "item-line" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("mission.creator")) + ":"),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.dialogData.createUserRealName)),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-line" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("notes")) + ":")]),
                    _c("span", [_vm._v(_vm._s(_vm.dialogData.remark))]),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "task-map-box" },
              [
                _c("task-replay-map", {
                  staticStyle: { height: "100%" },
                  attrs: {
                    videoList: _vm.videoList,
                    dialogData: _vm.dialogData,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }