var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    { staticClass: "project-task-box-01" },
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            "table-loading": _vm.loading,
            data: _vm.data,
            page: _vm.page,
            permission: _vm.permissionList,
            "before-open": _vm.beforeOpen,
          },
          on: {
            "update:page": function ($event) {
              _vm.page = $event
            },
            "row-del": _vm.rowDel,
            "search-change": _vm.searchChange,
            "search-reset": _vm.searchReset,
            "selection-change": _vm.selectionChange,
            "current-change": _vm.currentChange,
            "size-change": _vm.sizeChange,
            "refresh-change": _vm.refreshChange,
            "on-load": _vm.onLoad,
          },
          scopedSlots: _vm._u([
            {
              key: "uavId",
              fn: function (ref) {
                var row = ref.row
                return [_c("span", [_vm._v(_vm._s(row.airPlaneName || "-"))])]
              },
            },
            {
              key: "taskStatusName",
              fn: function (scope) {
                return [
                  _c(
                    "el-tag",
                    {
                      attrs: {
                        type:
                          scope.row.taskStatus == 3
                            ? "success"
                            : scope.row.taskStatus == 4
                            ? ""
                            : scope.row.taskStatus == 5
                            ? "warning"
                            : scope.row.taskStatus == 6
                            ? "danger"
                            : "info",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.statusMap[scope.row.taskStatus]) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: "memberIds",
              fn: function (ref) {
                var row = ref.row
                return [
                  _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.memberList
                            .filter(function (item) {
                              return row.memberIds.indexOf(item.value) > -1
                            })
                            .map(function (item) {
                              return item.label
                            })
                            .join(_vm.$t("comma"))
                        ) +
                        "\n      "
                    ),
                  ]),
                ]
              },
            },
            {
              key: "payloadId",
              fn: function (ref) {
                var row = ref.row
                return [_c("span", [_vm._v(_vm._s(row.loadName || "-"))])]
              },
            },
            {
              key: "dataLinkID",
              fn: function (ref) {
                var row = ref.row
                return [_c("span", [_vm._v(_vm._s(row.datalinkName || "-"))])]
              },
            },
            {
              key: "alarmState",
              fn: function (ref) {
                var row = ref.row
                return [
                  row.alarmState
                    ? _c("div", { staticClass: "abnormalCondition" }, [
                        _vm._v("有"),
                      ])
                    : _c("div", { staticClass: "abnormalCondition-no" }, [
                        _vm._v("无"),
                      ]),
                ]
              },
            },
            {
              key: "mixedTime",
              fn: function (scope) {
                return [
                  _c("div", [
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.planBeginTime) + " —— "),
                      ]),
                      _c("span", [_vm._v(_vm._s(scope.row.planFinishTime))]),
                    ]),
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.realBeginTime) + " —— "),
                      ]),
                      _c("span", [_vm._v(_vm._s(scope.row.realFinishTime))]),
                    ]),
                  ]),
                ]
              },
            },
            {
              key: "menu",
              fn: function (scope) {
                return [
                  _vm.arbitrament("view", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-view",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.viewDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        查看\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("view", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-circle-close",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.endTask(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        结束任务\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("videoReplay", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-video-camera-solid",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.viewVideoDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        视频回放\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("trackReplay", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-discover",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.viewDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        轨迹回放\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("trackReplay", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-download",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.downLoadReport(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        下载报告\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("del", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-delete",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.rowDel(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        删除\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("view", scope.row)
                    ? _c(
                        "el-dropdown",
                        {
                          staticClass: "copy-btn",
                          attrs: { placement: "bottom" },
                          on: { command: _vm.onCopy },
                        },
                        [
                          _c(
                            "span",
                            [
                              _c(
                                "el-button",
                                { attrs: { type: "text", size: "small" } },
                                [
                                  _vm._v(
                                    "\n            复制推流地址\n          "
                                  ),
                                ]
                              ),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down task-detail-drodown",
                              }),
                            ],
                            1
                          ),
                          (
                            scope.row.liveVideoVOList &&
                            Array.isArray(scope.row.liveVideoVOList)
                              ? true
                              : false
                          )
                            ? _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(
                                  scope.row.liveVideoVOList
                                    ? scope.row.liveVideoVOList
                                    : [],
                                  function (item) {
                                    return _c(
                                      "el-dropdown-item",
                                      {
                                        key: item.id,
                                        attrs: { command: [item, "push"] },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(item.liveDevice) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arbitrament("view", scope.row)
                    ? _c(
                        "el-dropdown",
                        {
                          staticClass: "copy-btn",
                          attrs: { placement: "bottom" },
                          on: { command: _vm.onCopy },
                        },
                        [
                          _c(
                            "span",
                            [
                              _c(
                                "el-button",
                                { attrs: { type: "text", size: "small" } },
                                [
                                  _vm._v(
                                    "\n            复制播放地址\n          "
                                  ),
                                ]
                              ),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down task-detail-drodown",
                              }),
                            ],
                            1
                          ),
                          (
                            scope.row.liveVideoVOList &&
                            Array.isArray(scope.row.liveVideoVOList)
                              ? true
                              : false
                          )
                            ? _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(
                                  scope.row.liveVideoVOList
                                    ? scope.row.liveVideoVOList
                                    : [],
                                  function (item) {
                                    return _c(
                                      "el-dropdown-item",
                                      {
                                        key: item.id,
                                        attrs: { command: [item, "pull"] },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(item.liveDevice) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    icon: "el-icon-plus",
                    type: "primary",
                  },
                  on: { click: _vm.addTask },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("btnAdd")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editAction ? _vm.$t("addTask") : _vm.$t("copyTask"),
            visible: _vm.editDialogVisible,
            width: "900px",
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.handleCloseEdit,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.editForm,
                rules: _vm.rules,
                "label-width": "auto",
                inline: "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("mission.name") + "：",
                    prop: "taskName",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      size: "small",
                      placeholder: _vm.$t("inputPlease"),
                    },
                    model: {
                      value: _vm.editForm.taskName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "taskName", $$v)
                      },
                      expression: "editForm.taskName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("executor") + "：",
                    prop: "memberIds",
                  },
                },
                [
                  _c("select-tree", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      data: _vm.memberList,
                      size: "small",
                      multiple: true,
                      clearable: true,
                      placeholder: _vm.$t("selectPlease"),
                    },
                    model: {
                      value: _vm.editForm.memberIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "memberIds", $$v)
                      },
                      expression: "editForm.memberIds",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("department") + "：", prop: "teamId" },
                },
                [
                  _c("select-tree", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      data: _vm.teamList,
                      size: "small",
                      clearable: true,
                      placeholder: _vm.$t("selectPlease"),
                    },
                    model: {
                      value: _vm.editForm.teamId,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "teamId", $$v)
                      },
                      expression: "editForm.teamId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("mission.executionTime") + "：",
                    prop: "executionTime",
                  },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "396px" },
                    attrs: {
                      size: "small",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetimerange",
                      "range-separator": _vm.$t("to"),
                      "start-placeholder": _vm.$t("startTime"),
                      "end-placeholder": _vm.$t("endTime"),
                      "default-time": ["00:00:00", "23:59:59"],
                      placeholder: _vm.$t("selectPlease"),
                    },
                    model: {
                      value: _vm.editForm.executionTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "executionTime", $$v)
                      },
                      expression: "editForm.executionTime",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("mission.usingUAV") + "：",
                    prop: "airPlaneID",
                  },
                },
                [
                  _c("select-tree", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      data: _vm.airPlaneList,
                      size: "small",
                      clearable: true,
                      placeholder: _vm.$t("selectPlease"),
                    },
                    model: {
                      value: _vm.editForm.airPlaneID,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "airPlaneID", $$v)
                      },
                      expression: "editForm.airPlaneID",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "4px" },
                      attrs: { type: "primary", size: "small", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.chooseEquip("uav")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("mission.selectEquipment")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("mission.usingPod") + "：" } },
                [
                  _c("select-tree", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      data: _vm.loadList,
                      size: "small",
                      clearable: true,
                      placeholder: _vm.$t("selectPlease"),
                    },
                    model: {
                      value: _vm.editForm.loadID,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "loadID", $$v)
                      },
                      expression: "editForm.loadID",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "4px" },
                      attrs: { type: "primary", size: "small", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.chooseEquip("pod")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("mission.selectEquipment")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("mission.usingDataLink") + "：" } },
                [
                  _c("select-tree", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      data: _vm.dataLinkList,
                      size: "small",
                      clearable: true,
                      placeholder: _vm.$t("selectPlease"),
                    },
                    model: {
                      value: _vm.editForm.dataLinkID,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "dataLinkID", $$v)
                      },
                      expression: "editForm.dataLinkID",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "4px" },
                      attrs: { type: "primary", size: "small", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.chooseEquip("datalink")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("mission.selectEquipment")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.handleCloseEdit },
                },
                [_vm._v(_vm._s(_vm.$t("btnCancel")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.submitLoading,
                      expression: "submitLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submitTask },
                },
                [_vm._v(_vm._s(_vm.$t("btnOk")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "equip-dialog-001",
          attrs: {
            title: "设备列表",
            visible: _vm.equipmentDialogVisible,
            width: "950px",
            "append-to-body": true,
            "before-close": _vm.closeEquipmentDialog,
            modal: true,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.equipmentDialogVisible = $event
            },
          },
        },
        [
          _vm.equipmentDialogVisible
            ? _c("FormLayer", {
                ref: "FormLayer",
                attrs: {
                  equipType: _vm.equipType,
                  projectId: _vm.projectId,
                  teamId: _vm.form.teamId,
                  column: _vm.equipColumn,
                },
                on: { changeSelections: _vm.changeSelections },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.equipmentDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.selectEquip } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.trackDetailVisible
        ? _c("DetailDialog", {
            attrs: {
              dialogData: _vm.dialogData,
              dialogVisible: _vm.trackDetailVisible,
            },
            on: { closeDialog: _vm.trackCloseDetail },
          })
        : _vm._e(),
      _vm.videoHistoryDialogVisible
        ? _c("VideoDialog", {
            attrs: {
              taskPlayBackData: _vm.taskPlayBackData,
              videoDialogData: _vm.videoDialogData,
              videoDialogVisible: _vm.videoHistoryDialogVisible,
            },
            on: { videoCloseDialog: _vm.videoCloseDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }