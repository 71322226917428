<template>
  <div class="project-manage">
    <div class="project-status">
      <el-select
        class="uav-select"
        v-model="projectStatus"
        @change="search()"
        placeholder="全部状态">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input
        class="project-search-input uav-input"
        v-model="projectName"
        onclick="search"
        placeholder="请输入内容"
      >
        <template slot="suffix">
          <div class="search-icon-container">
            <i class="el-icon-search" @click="search()"></i>
          </div>
        </template>
      </el-input>
    </div>
    <div class="project-list">
      <div
        v-for="item in projectData"
        :key="item.id"
        @click="chooseProject(item)"
        :class="item.id === selectProject? 'project-list-item project--choosen': 'project-list-item'">
        <p class="item-top">
          <span class="item-top-left">
            <span class="item-top-title">{{ item.projectName }}</span>
            <StatusLabel :labelStatus="item.projectStatus"/>
          </span>

          <span @click.stop>
            <el-dropdown 
              v-if="(permission.project_edit||permission.project_del) && hasProjectView"
              @command="changeItem">
              <span class="el-dropdown-link">
                <img
                  class="icon icon-more"
                  src="/img/project/icon-more.png"
                  alt=""
                />
              </span>
              <el-dropdown-menu class="black-style" slot="dropdown">
                <el-dropdown-item
                  v-if="item.projectStatus !== '2'&& permission.project_edit"
                  :command="beforeHandleCommand('edit', item)"
                  >编辑</el-dropdown-item
                >
                <!-- <el-dropdown-item
                  :command="beforeHandleCommand('finish', item)"
                >
                  结束
                </el-dropdown-item> -->

                <el-dropdown-item
                  v-if="permission.project_del"
                  :command="beforeHandleCommand('delete', item)"
                >
                  删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </p>
        <p class="item-bottom">
          <span class="item-title">项目类型：{{ item.projectTypeDesc }}</span>
        </p>

        <p class="item-bottom">
          <span class="item-title">项目团队：{{ item.deptNames }}</span>
        </p>

        <p class="item-bottom">
          <span class="item-title">负责人：{{ item.managerName }}</span>
        </p>

        <p class="item-bottom">
          <span class="item-title">任务数量：{{ item.taskAmount }}</span>
        </p>

        <p class="item-bottom">
          <span class="item-bottom-time">开始时间：{{ item.startTime }}</span>
        </p>

        <p class="item-bottom">
          <span class="item-bottom-time">结束时间：{{ item.finishTime }}</span>
        </p>

        <p class="item-right"  @click.stop="toDetail(item)">
          <span
            v-if="showProjectDetail"
            class="item-bottom-project">
            进入项目>>
          </span>
        </p>
      </div>
    </div>
    <div class="project-add">
      <el-button
        v-if="permission.project_add && hasProjectView"
        class="project-add-btn"
        type="primary"
        icon="el-icon-plus"
        @click="addProject"
        >新增项目</el-button
      >
    </div>
  </div>
</template>

<script>
import StatusLabel from "@/components/project/statusLabel";
import { mapGetters, mapMutations } from "vuex";
import { getProjectList, finishProject, deleteProject } from "@/api/project/index";
import { getList } from '@/api/system/dept'

export default {
  name: "ProjectList",
  components: {
    StatusLabel,
  },
  computed: {
    ...mapGetters([
      'selectProject',
      'permission',
      'menuAll'
    ]),
    hasProjectView() {
      for (var i = 0; i < this.menuAll.length; i++) {
        const item = this.menuAll[i]
        if (item.code === 'mission-center') {
          if (item.children && Array.isArray(item.children)) {
            for (let j = 0; j < item.children.length; j++) {
              const element = item.children[j];
              if (element.code === 'project-manager') {
                if (element.children && Array.isArray(element.children) && element.children.length > 0) {
                  let children = element.children
                  for (let k = 0; k < children.length; k++) {
                    if (children[k].code === 'project_detail') {
                      return true
                    }
                  }
                }
              }
            }
          }
        }
      }
      return false
    },
    showProjectDetail() {
      for (var i = 0; i < this.menuAll.length; i++) {
        const item = this.menuAll[i]
        if (item.code === 'mission-center') {
          if (item.children && Array.isArray(item.children)) {
            for (let j = 0; j < item.children.length; j++) {
              const element = item.children[j];
              if (element.code === 'project-manager') {
                if (element.children && Array.isArray(element.children) && element.children.length > 0) {
                  return true
                }
              }
            }
          }
        }
      }
      return false
    }
  },
  data() {
    return {
      projectName: "",
      labelStatus: "",
      options: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "未开始",
        },
        {
          value: "2",
          label: "进行中",
        },
        {
          value: "3",
          label: "已完成",
        },
      ],
      projectStatus: "",
      data: [],
      projectData: [],
      deptTree:[]
    };
  },
  mounted() {
    this.getList({projectStatus: 0});
    this.getDeptList()
  },
  methods: {
    ...mapMutations([
      'SET_MAP3D_PROJECT_LIST'
    ]),
    beforeHandleCommand(flag, command) {
      return {
        flag: flag,
        command: command,
      };
    },
    changeItem(val) {
      const item = val.command;
      const type = val.flag;
      switch (type) {
        case "edit":
          this.$emit("editProject", item);
          this.$store.commit("SET_PROJECT", item);
          break;
        case "finish":
          finishProject({
            projectId: item.id,
          }).then((res) => {
            if (res.data.code === 200) {
              this.$message.success(res.data.msg);
              this.getList({projectStatus: 0});
            }
          });
          break;
        case "delete":
          this.$confirm("是否确认删除该项目", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              deleteProject({
                ids: item.id,
              }).then((res) => {
                if (res.data.code === 200) {
                  this.$message({
                    type: "success",
                    message: res.data.msg,
                  });
                  this.getList({projectStatus: 0});
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        default:
          return;
      }
    },
    getDeptList() {
      getList().then(res=>{
        if (res.data.code === 200) {
          this.deptTree = res.data.data
        }
      })
    },
    //选择项目
    chooseProject(item) {
      this.$store.dispatch("setSelectProject", item.id);
    },
    toDetail(item) {
      this.$emit("detailProject", item, true);
      this.$store.commit("SET_PROJECT", item);
    },
    addProject() {
      this.$emit("addProject");
    },
    search(){
       this.getList({projectStatus:this.projectStatus,projectName:this.projectName});
    },
    getList(parmas) {
      parmas.current = 1;
      parmas.size = 10000;
      getProjectList(parmas).then((res) => {
        if (res.data.code === 200) {
          this.projectData = res.data.data.records;
          this.SET_MAP3D_PROJECT_LIST(res.data.data.records)
        }
      })
    },
    getDepartment(ids) {
      let res = ''
      if (typeof ids === 'string') {
        ids = JSON.parse(ids)
      }
      if (ids && Array.isArray(ids)) {
        for (var i = 0; i < ids.length; i++) {
          res = res + this.getDeptNameFromTree(ids[i])
        }
      }
      return res
    },
    getDeptNameFromTree(id, list) {
      if (!list) {
        list = this.deptTree
      }
      let res = ''
      for (var i = 0; i < list.length; i++) {
        if (list.children && Array.isArray(list.children)) {
          res = this.getDeptNameFromTree(id, list.children)
        }
        if (id === list[i].id) {
          res = list[i].deptName
          return res
        }
      }
      return res
    }
  },
  beforeDestroy() {
    // 清空缓存
    this.$store.dispatch("setSelectProject", null);
  }
};
</script>

<style lang="scss">
 .project--choosen{
  background-color: #40434a;
 }

.project-status {
  .uav-select {
    .el-input input {
      width: 89px;
      height: 30px;
    }
  }
}
.source-branch-selection-popper {
  top: 55px !important;
}
</style>
<style lang="scss" scoped>
.project-manage {
  width: 100%;
  padding-top: 5px;
  height: 100%;
  .project-search {
    display: flex;
    &-input {
      width: 177px;
      height: 30px;
      margin-left: 10px;
      margin-right: 7px;
    }
    &-btn {
      width: 57px;
      height: 30px;
      background: #265edd;
      border-radius: 2px;
      border: none;
    }
  }
  
  .project-status {
    width: 290px;
    margin: 5px 0 0 5px;
    .uav-select {
      .el-input input {
        font-size: 14px;
        font-family: MicrosoftYaHeiUI;
        color: #ffffff;
      }
    }
  }

  .project-list {
    margin-top: 5px;
    max-height: calc(100% - 120px);
    overflow-y: overlay;
    width: 290px;

    &-item {
      padding: 13px 23px 11px 16px;
      box-sizing: border-box;
      position: relative;
      border-top: 2px solid #030303;
      cursor: pointer;

      &--choosen {
        background-color: red;
      }

      .icon {
        &-more {
          // position: absolute;
          // top: 7px;
          // right: 15px;
          height: 10px;
          cursor: pointer;
          padding: 5px 10px;
          margin-right: -6px;
        }
        &-location {
          position: absolute;
          top: 32px;
          right: 11px;
          height: 14px;
          cursor: pointer;
          padding: 5px 10px;
        }
        &-user {
          width: 11px;
          line-height: 14px;
        }
      }

      &:last-child {
        border-bottom: 1px solid #030303;
      }

      .item-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 9px;
        &-left {
          display: flex;
          justify-content: flex-start;
          max-width: 230px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &-title {
          font-size: 14px;
          font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
          font-weight: bold;
          color: #ffffff;
          margin-right: 10px;
        }
      }

      .item-center {
        font-size: 12px;
        font-family: MicrosoftYaHeiUI;
        color: #ffffff;
        line-height: 15px;
        margin-bottom: 25px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 50px;
      }

      .item-right {
        font-size: 12px;
        font-family: MicrosoftYaHeiUI;
        color: #ffffff;
        line-height: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: right;
      }

      .item-top-title {
        display: inline-block;
        width: 120px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .item-bottom {
        display: flex;
        margin-bottom: 8px;
        justify-content: space-between;
        font-size: 12px;
        font-family: MicrosoftYaHeiUI;
        color: #ffffff;
        &-user {
          display: flex;
          align-items: center;
          .user {
            margin-left: 4px;
          }
        }
        .item-bottom-time{
          color: rgba(153, 153, 153, 0.79);
        }
      }
    }
  }

  .project-add {
    margin-top: 15px;
    margin-left: 10px;
    &-btn {
      width: 267px;
      height: 36px;
      line-height: 36px;
      background: #265edd;
      border-radius: 3px;
      font-size: 14px;
      font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
      font-weight: bold;
      color: #ffffff;
      padding: 0;
      border: none;
    }
  }
}
::v-deep .el-scrollbar__view.el-select-dropdown__list {
  background: #000000 !important;
  .el-select-dropdown__item {
    color: #ffffff !important;
  }
}

::v-deep .popper__arrow {
  border-top-color: #000000 !important;
  border-bottom-color: #000000 !important;
  &::after {
    border-top-color: #000000 !important;
    border-bottom-color: #000000 !important;
  }
}

::v-deep .el-select-dropdown {
  border: none !important;
}

::v-deep .el-select-dropdown__item.hover {
  background: #0f1219 !important;
}

.search-icon-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
