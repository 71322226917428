<template>
  <basic-container
    id="record-task-box">
    <!-- crud -->
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      :page.sync="page"
      :permission="permissionList"
      v-model="form"
      ref="crud"
      @row-del="rowDel"
      @search-change="searchChange"
      @search-reset="searchReset"
      @selection-change="selectionChange"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <!-- 时间组件 -->
      <template
        slot="mixedTime"
        slot-scope="scope">
        <div>
          <div>
            <span>{{ scope.row.startTime }} —— </span>
            <span>{{ scope.row.endTime }}</span>
          </div>
        </div>
      </template>
      <!-- 标题栏 -->
      <template
        slot="menuLeft">
        <el-button size="small" icon="el-icon-plus" @click="addTask" type="primary">{{$t('btnAdd')}}</el-button>
      </template>
      <!-- 操作栏 -->
      <template
        slot-scope="scope"
        slot="menu">
        <!-- 任务回放 -->
        <el-button
          :ref="'RecordBtn' + scope.row.id"
          type="text"
          size="small"
          icon="el-icon-data-line"
          @click="showRecord(scope.row, 'RecordBtn' + scope.row.id)"
        >
          任务回放
        </el-button>
        <!-- 编辑 -->
        <el-button
          type="text"
          size="small"
          icon="el-icon-edit"
          @click="rowEdit(scope.row)"
        >
          编辑
        </el-button>
        <!-- 视频文件 -->
        <el-button
          type="text"
          size="small"
          icon="el-icon-files"
          @click="showFiles(scope.row)"
        >
          视频文件
        </el-button>
        <!-- 删除  -->
        <el-button
          :ref="'removeBtn' + scope.row.id"
          type="text"
          size="small"
          icon="el-icon-delete"
          @click="rowDel(scope.row, 'removeBtn' + scope.row.id)"
        >
          删除
        </el-button>
      </template>
    </avue-crud>
    <!-- 新增弹窗 -->
    <el-dialog
      :title="action? $t('addTask'): $t('editTask')"
      :visible.sync="editDialogVisible"
      width="800px"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleCloseEdit">
      <el-form 
        :model="editForm"
        :rules="rules"
        ref="editForm"
        label-width="auto"
        inline>
        <!-- 任务名称 -->
        <el-form-item
          :label="`${$t('mission.name')}：`"
          prop="name">
          <el-input
            style="width: 248px;"
            v-model="editForm.name" 
            size="small"
            :placeholder="$t('inputPlease')">
          </el-input>
        </el-form-item>
        <!-- 业务分类 -->
        <el-form-item
          :label="`${$t('mission.businessType')}：`"
          prop="businessType">
          <select-tree
            v-model="editForm.businessType"
            :data="businessTypeList"
            size="small"
            style="width: 248px;"
            :placeholder="$t('selectPlease')">
          </select-tree>
        </el-form-item>
        <!-- 使用飞机 -->
        <el-form-item
          :label="`${$t('mission.usingUAV')}：`"
          prop="equipmentId">
          <select-tree
            v-model="editForm.equipmentId"
            :data="equipmentList"
            size="small"
            style="width: 248px;"
            :placeholder="$t('selectPlease')">
          </select-tree>
        </el-form-item>
        <!-- 执行时间 -->
        <el-form-item
          :label="`${$t('mission.executionTime')}：`"
          prop="executionTime">
          <el-date-picker
            v-model="editForm.executionTime"
            size="small"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 396px;"
            type="datetimerange"
            :range-separator="$t('to')"
            :start-placeholder="$t('startTime')"
            :end-placeholder="$t('endTime')"
            :default-time="['00:00:00', '23:59:59']"
            :placeholder="$t('selectPlease')">
          </el-date-picker>
        </el-form-item>
        <!-- 备注 -->
        <el-form-item
          :label="`${$t('notes')}：`"
          prop="remark">
          <el-input 
            type="textarea" 
            size="small"
            style="width: 396px;"
            :placeholder="$t('inputPlease')"
            v-model="editForm.remark">
          </el-input>
        </el-form-item>
        <!-- 上传视频 -->
        <el-form-item
          class="is-required"
          :label="`${$t('mission.uploadVideo')}：`">
          <el-upload
            style="width: 248px;"
            :headers="headers"
            :action="`/api/blade-resource/oss/endpoint/upload-file`"
            :on-remove="videoHandleRemove"
            :before-remove="videoBeforeRemove"
            multiple
            :data="fileData"
            :name="'file'"
            :limit="videoLimit"
            :on-exceed="videoHandleExceed"
            :on-success="videoHandleSuccess"
            :before-upload="videoHandleBeforeUpload"
            :on-error="videoHandleError"
            :file-list="videoFileList">
            <el-button size="small" type="primary">{{ $t('clickToUpload') }}</el-button>
            <div slot="tip" class="el-upload__tip">{{ $t('mission.uploadVideoDesc') }}</div>
          </el-upload>
        </el-form-item>
        <!-- 航线上传 -->
        <el-form-item
          :label="`${$t('mission.uploadRoute')}：`">
          <el-upload
            style="width: 248px;"
            :headers="headers"
            :action="`/api/blade-resource/oss/endpoint/upload-file`"
            :data="fileData"
            :multiple="false"
            :on-remove="RouteHandleRemove"
            :before-remove="RouteBeforeRemove"
            :on-exceed="RouteHandleExceed"
            :on-success="RouteHandleSuccess"
            :before-upload="RouteHandleBeforeUpload"
            :file-list="routeFileList"
            >
            <el-button size="small" type="primary">{{ $t('clickToUpload') }}</el-button>
            <div slot="tip" class="el-upload__tip">{{ $t('mission.uploadRouteDesc') }}</div>
          </el-upload>
        </el-form-item>
        <!-- 作业区上传 -->
        <el-form-item
          :label="`${$t('mission.uploadOperationArea')}：`">
          <el-upload
            style="width: 248px;"
            :headers="headers"
            :action="`/api/blade-resource/oss/endpoint/upload-file`"
            :multiple="false"
            :data="fileData"
            :on-remove="operationAreaHandleRemove"
            :before-remove="operationAreaBeforeRemove"
            :on-success="operationAreaHandleSuccess"
            :on-exceed="operationAreaHandleExceed"
            :before-upload="operationAreaHandleBeforeUpload"
            :file-list="workAreaFileList">
            <el-button size="small" type="primary">{{ $t('clickToUpload') }}</el-button>
            <div slot="tip" class="el-upload__tip">{{ $t('mission.uploadOperationAreaDesc') }}</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" >
        <el-button
          size="small"
          @click="handleCloseEdit">
          {{$t('btnCancel')}}
        </el-button>
        <el-button
          size="small"
          v-loading="submitLoading"
          type="primary"
          :disabled="count > 0"
          @click="submitTask">
          {{$t('btnOk')}}
        </el-button>
      </span>
    </el-dialog>
    <!-- 任务回放 -->
    <task-replay-dialog
      v-if="taskReplayVisible"
      :videoList="videoForMapList"
      :dialogData="dialogData"
      :dialogVisible="taskReplayVisible"
      @closeDialog="closetaskReplayVisible"
    />
    <!-- 视频文件 -->
    <el-dialog
      :title="$t('videoFile')"
      :visible.sync="videoDialogVisible"
      width="400px"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleCloseVideo">
      <div>
        <el-checkbox 
          :indeterminate="isIndeterminate"
          v-model="checkFileAll"
          @change="handleCheckAllChange">{{$t('checkAll')}}</el-checkbox>
        <div style="margin: 30px 0;"></div>
        <el-checkbox-group 
          v-model="checkedFileList"
          @change="handleCheckedCitiesChange">
          <div
            class="record-task-file-checkbox-item"
            style="margin-bottom: 10px"
            v-for="item in videoLoadingFileList"
            :key="item.url">
            <el-checkbox :label="item.url" >{{item.name}}</el-checkbox>
            <i
              class="icon file-checkbox-item-icon el-icon-download"
              @click="downloadFile(item)"
              >
            </i>
          </div>
        </el-checkbox-group>
      </div>
      <span slot="footer" >
        <el-button size="small" @click="handleCloseVideo">{{$t('closeText')}}</el-button>
        <el-button size="small" type="primary" @click="downLoadAllFile">{{$t('downLoadAll')}}</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>

<script>
import {
  getList,
  submit,
  getDetail,
  remove
} from "@/api/phugiacloud/recordTask";

import { removeFile } from '@/api/resource/oss';
import { getUseEquipmentList } from "@/api/phugiacloud/projectManagement";
import { getList as  getDeptList } from '@/api/system/dept';
import { getuserList } from "@/api/system/user";
import { mapGetters } from "vuex";
import { getToken } from "@/util/auth";
import website from "@/config/website";
import taskReplayDialog from "@/components/task-replay-dialog";

export default {
  name: "recordTask",
  components: { taskReplayDialog },
  data() {
    return {
      // 新增编辑
      action: true,
      // 编辑弹窗
      editDialogVisible: false,
      // 编辑表单
      editForm: {
        // 任务名称
        name: '',
        // 业务分类
        businessType: '',
        // 使用飞机
        equipmentId: '',
        // 执行时间
        executionTime: [],
        // 备注
        remark: '',
        // 上传视频
        videoIds: [],
        // 上传航线
        routeLineAttachId: '',
        // 上传作业区
        workAreaAttachId: ''
      },
      // 效验规则
      rules: {
        name: [{ required: true, message: this.$t('inputPlease'), trigger: 'blur' }],
        businessType: [{ required: true, message: this.$t('inputPlease'), trigger: 'blur' }],
        executionTime: [{ required: true, message: this.$t('inputPlease'), trigger: 'blur' }],
        equipmentId: [{ required: true, message: this.$t('inputPlease'), trigger: 'blur' }],
      },
      // 编辑弹窗提交按钮
      submitLoading: false,
      // 上传header 保留
      headers: {
        "Blade-Auth": "bearer " + getToken(),
        Authorization: `Basic ${Base64.encode(
          `${website.clientId}:${website.clientSecret}`
        )}`,
      },
      query: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      // 勾选列表
      selectionList: [],
      data: [],
      // 业务分类列表
      businessTypeList: [
        {
          label: '巡检',
          value: 'inspection'
        },
        {
          label: '应急',
          value: 'emergency'
        },
        {
          label: '安环',
          value: 'safeEnv'
        }
      ],
      // 项目id
      projectId: this.$route.query.projectId,
      // 团队列表
      deptList: [],
      // 用户名单
      userList: [],
      // 任务类型列表
      taskTypeList: [
        {
          label: this.$t('mission.taskRecord'),
          value: 'record'
        }
      ],
      // 设备列表
      equipmentList: [],
      fileData: {
        bucketName: '',
        business: '',
        module: '',
        path: '',
        system: ''
      },
      // 视频上传列表
      videoFileList: [],
      // 视频上传限制
      videoLimit: 3,
      // 航线上传列表
      routeFileList: [],
      // 航线上传限制
      routeLimit: 1,
      // 作业区上传列表
      workAreaFileList: [],
      // 作业区上传限制
      operationAreaLimit: 1,
      // 任务回放
      taskReplayVisible: false,
      // 任务回访弹窗传值
      dialogData: {},
      // 视频文件
      videoDialogVisible: false,
      // 全选
      checkFileAll: false,
      // 
      isIndeterminate: false,
      // 视频文件可下载文件
      videoLoadingFileList: [],
      // 当前选中的下载文件
      checkedFileList: [],
      // 视频源
      videoForMapList: [],
      // 
      count: 0
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: true,
        viewBtn: true,
        delBtn: true,
        editBtn: true,
      };
    },
    projectId() {
      return this.$route.query.projectId;
    },
    option() {
      return {
        height: 'auto',
        calcHeight: 110,
        tip: false,
        searchShow: true,
        searchMenuSpan: 4,
        border: true,
        index: true,
        viewBtn: false, //重写查看按钮，自带的不要
        selection: true,
        dialogClickModal: false,
        excelBtn: false,
        columnBtn: false,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        menuWidth: 310,
        cellBtn: false,
        excelBtn: false,
        labelWidth: 110,
        menuFixed: "right",
        searchBtnText: this.$t('searchBtn'),
        column: [
          // 任务名称
          {
            label: this.$t('mission.name'),
            prop: "name",
            width: 120,
            search: true
          },
          // 任务类型
          {
            label: this.$t('mission.taskType'),
            prop: "type",
            width: 80,
            formatter:(row, value, label)=>{
              var node = this.taskTypeList.find(item=>{
                return item.value === value
              })
              return node && node.label? node.label: '--'
            }
          },
          // 业务分类
          {
            display: false,
            label: this.$t('mission.businessType'),
            prop: "businessType",
            formatter:(row, value, label)=>{
              var node = this.businessTypeList.find(item=>{
                return item.value === value
              })
              return node && node.label? node.label: '--'
            },
            width: 120,
          },
          // 飞行器
          {
            display: false,
            label: this.$t('mission.aerocraft'),
            prop: "equipmentName",
            width: 120,
            slot: true,
          },
          // 执行时间
          {
            label: this.$t('mission.executionTime'),
            prop: "mixedTime",
            span: 24,
            width: 280,
            display: false,
          },
          // 所属团队
          {
            label: this.$t('department'),
            width: 120,
            prop: "deptIds",
            formatter: (row, column, value)=>{
              var deptList = this.deptList.filter(item=>{
                return value.indexOf(item.id) > -1
              })
              var deptNameList = deptList.map(item=>{
                return item.deptName
              })
              const txt = deptNameList.length > 0? deptNameList.join(','): '--'
              return txt
            }
          },
          // 创建人
          {
            label: this.$t('mission.creator'),
            width: 120,
            prop: "createUser",
            formatter: (row, column, value)=>{
              var item = this.userList.find(item=>{
                return value === item.value
              })
              return item? item.label: '--'
            }
          },
          // 任务编号
          {
            label: this.$t('mission.taskCode'),
            prop: "id",
            width: 200,
            display: false,
          },
          // 备注
          {
            label: this.$t('notes'),
            width: 200,
            prop: "remark"
          }
        ]
      }
    }
  },
  mounted() {
    this.getDeptList()
    this.getuserList()
    this.getUseEquipmentList()
    this.$nextTick(()=>{
      this.size()
    })
  },
  methods: {
    // 计算表格高度
    size () {
      const box = document.getElementById('record-task-box')
      const rectangle = box.getBoundingClientRect()
      this.currentHeigt = `${rectangle.height - 180}px`
    },
    // 增加任务
    addTask() {
      this.action = true
      this.editDialogVisible = true
    },
    // 关闭编辑弹窗
    handleCloseEdit() {
      this.$refs.editForm.clearValidate()
      // this.$refs.editForm.resetFields()
      this.editForm = {
        // 任务名称
        name: '',
        // 业务分类
        businessType: '',
        // 使用飞机
        equipmentId: '',
        // 执行时间
        executionTime: [],
        // 备注
        remark: '',
        // 上传视频
        videoIds: [],
        // 上传航线
        routeLineAttachId: '',
        // 上传作业区
        workAreaAttachId: ''
      }
      this.count = 0
      this.videoFileList = []
      this.routeFileList = []
      this.workAreaFileList = []
      this.editDialogVisible = false
    },
    // 提交新增任务
    submitTask() {
      this.$refs.editForm.validate(value=>{
        if (value) {
          if ( this.editForm.videoIds.length < 1) {
            return this.$message.warning('请上传视频文件')
          } 
          this.submitLoading = true
          if (this.action) {
            let params = {
              ...this.editForm
            }
            params.videoIds = params.videoIds.join(',')
            params.startTime = params.executionTime[0]
            params.endTime = params.executionTime[1]
            params.projectId = this.$route.query.projectId
            params.projectName = this.$route.query.projectName
            params.equipmentName = this.equipmentList.find(item=>{
              return item.value === params.equipmentId
            }).label
            delete params.executionTime
            submit(params).then(res=>{
              if (res.data.code === 200) {
                this.onLoad(this.page)
                this.handleCloseEdit()
                this.$message.success(res.data.msg)
              }
            }).finally(()=>{
              this.submitLoading = false
            })
          } else {
            let params = {
              ...this.editForm
            }
            params.videoIds = params.videoIds.join(',')
            params.startTime = params.executionTime[0]
            params.endTime = params.executionTime[1]
            params.equipmentName = this.equipmentList.find(item=>{
              return item.value === params.equipmentId
            }).label
            delete params.executionTime
            submit(params).then(res=>{
              if (res.data.code === 200) {
                this.onLoad(this.page)
                this.handleCloseEdit()
                this.$message.success(res.data.msg)
              }
            }).finally(()=>{
              this.submitLoading = false
            })
          }
        }
      })
    },
    // 任务回访
    showRecord(row, refName) {
      this.$refs[refName].loading = true
      getDetail({
        id: row.id
      }).then((res) => {
        if (res.data.code === 200) {
          var data = {
            ...res.data.data
          }
          const deptlist = this.deptList.filter(item=>{
            return data.deptIds.indexOf(item.id) > -1
          })
          data.deptNames = deptlist.map(item=>{
            return item.deptName
          }).join(',')
          const userList = this.userList.filter(item=>{
            return data.createUser === item.value
          })
          data.createUserRealName = userList[0]? userList[0].label: undefined
          const taskType = this.taskTypeList.filter(item=>{
            return item.value = data.type 
          })
          data.taskType = taskType[0]? taskType[0].label: undefined
          this.dialogData = data
          this.videoForMapList = row.videoAttachList.map(item=>{
            return {
              label: item.originalName,
              value: item.link
            }
          })
          this.taskReplayVisible = true
        }
      }).finally(()=>{
        this.$refs[refName].loading = false
      })
    },
    // 视频文件
    showFiles(row) {
      this.videoDialogVisible = true
      this.videoLoadingFileList = row.videoAttachList.map(item=>{
        return {
          url: item.link,
          name: item.originalName
        }
      })
    },
    // 编辑
    rowEdit(row) {
      this.action = false
      let data = { 
        id: row.id,
        routeLineAttachId: row.routeLineAttachId,
        name: row.name,
        workAreaAttachId: row.name,
        deptIds: row.deptIds,
        equipmentId: row.equipmentId,
        endTime: row.endTime,
        sn: row.sn,
        projectId: row.projectId,
        equipmentName: row.equipmentName,
        startTime: row.startTime,
        businessType: row.businessType,
        projectName: row.projectName,
        videoIds: row.videoIds,
        remark: row.remark
      }
      data.executionTime = [data.startTime, data.endTime]
      // 视频文件
      data.videoIds = row.videoAttachList.map(item=>{
        return item.id
      })
      this.videoFileList = row.videoAttachList.map(item=>{
        return {
          name: item.originalName,
          url: item.link,
          attachId: item.id
        }
      })

      // 航线文件
      data.routeLineAttachId = ''
      if (row.routeLineAttach && row.routeLineAttach.id) {
        data.routeLineAttachId = row.routeLineAttach.id
        this.routeFileList = [{
          name: row.routeLineAttach.originalName,
          url: row.routeLineAttach.link,
          attachId: row.id
        }]
      }
      
      // 作业区文件
      data.workAreaAttachId = ''
      if (row.workAreaAttach && row.workAreaAttach.id) {
        data.workAreaAttachId = row.workAreaAttach.id
        this.workAreaFileList = [{
          name: row.workAreaAttach.originalName,
          url: row.workAreaAttach.link,
          attachId: row.id
        }]
      }
      
     
      // 弹窗赋值
      this.editForm = data
      this.editDialogVisible = true
    },
    // 删除
    rowDel(row, refName) {
      this.$confirm(`${this.$t('needToDelete')}${this.$t('questionMark')}`, {
        confirmButtonText: this.$t('submitText'),
        cancelButtonText: this.$t('cancelText'),
        type: "warning",
      }).then(() => {
          this.$refs[refName].loading = true
          remove(row.id).then(res=>{
            if (res.data.code === 200) {
              this.$message.success(res.data.msg);
              this.onLoad(this.page);
            }
          }).finally(()=>{
            this.$refs[refName].loading = false
          });
      }).catch(()=>{})
    },
    // 重置
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    // 
    searchChange(params, done) {
      params.projectId = this.projectId;
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done()
    },
    // 
    selectionChange(list) {
      this.selectionList = list;
    },
    // 
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    // 
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    // 
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    // 刷新
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    // 加载表格数据
    onLoad(page, params = {}) {
      this.loading = true;
      this.query.projectId = this.projectId;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.selectionClear();
        this.$nextTick(() => {
          this.$refs.crud.doLayout();
        });
      }).finally(()=>{
        this.loading = false;
      })
    },
    // 获取部门
    getDeptList() {
      getDeptList().then(res=>{
        if (res.data.code === 200) {
          this.deptList = res.data.data
        }
      })
    },
    // 获取用户列表
    getuserList() {
      getuserList(1, 9999).then(res=>{
        if (res.data.code === 200) {
          this.userList = res.data.data.records.map(item=>{
            return {
              value: item.id,
              label: item.realName
            }
          })
        }
      })
    },
    // 获取无人机
    getUseEquipmentList() {
      getUseEquipmentList({
        projectId: this.projectId,
        equipmentType: 'uav',
        page: 9999
      }).then((res) => {
        if (res.data.code === 200) {
          const data = res.data.data;
          this.equipmentList = data.map(item=>{
            return {
              value: item.id,
              label: item.equipmentName
            }
          });
        }
      })
    },
    /*********** 视频上传 ***********/
    videoHandleRemove(file, fileList) {
      this.editForm.videoIds = this.editForm.videoIds.filter(id => {
        return id !== file.attachId
      })
    },
    videoHandleExceed(files, fileList) {
      this.$message.warning(
        `
          ${this.$t('mission.uploadFileLimitText1')} 
          ${this.videoLimit} 
          ${this.$t('mission.uploadFileLimitText2')} 
          ${files.length} 
          ${this.$t('mission.uploadFileLimitText3')} 
          ${files.length + fileList.length} 
          ${this.$t('mission.uploadFileLimitText4')} 
        `
      );
    },
    videoBeforeRemove(file, fileList) {
      return new Promise((resolve, reject)=>{
        this.$confirm(`${this.$t('ConfirmToDelete')} ${ file.name }${this.$t('questionMark')}`).then(()=>{
          this.count = this.count > 0? this.count - 1: 0
          if (!file.attachId) {
            return resolve()
          }
          removeFile({
            ids: file.attachId
          }).then(res=>{
            if (res.data.code === 200) {
              resolve()
              this.$message.success(res.data.msg)
            }
            reject()
          }).catch(()=>{
            reject()
          })
        }).catch(()=>{
          reject()
        })
      })
    },
    videoHandleSuccess(res, file, fileList) {
      if (res.code === 200) {
        const data = res.data
        const attachId = data.attachId
        for (let i = 0; i < fileList.length; i++) {
          var item = fileList[i]
          if (item.uid === file.uid) {
            file.attachId = attachId
            item.attachId = attachId
            this.editForm.videoIds.push(attachId)
          }
        }
      } else {
        fileList.pop()
      }
      this.count = this.count > 0? this.count - 1: 0
    },
    videoHandleBeforeUpload(file) {
      return new Promise((resolve, reject)=>{
        if(file.name) {
          var index = file.name.lastIndexOf('.')
          var type = file.name.slice(index, index.length)
          if (type === '.mp4' || type === '.flv') {
            this.count++
            resolve()
          }else {
            this.$message.error('支持格式：mp4, flv')
            setTimeout(() => {
              reject()
            }, 2000);
          }
        }else{
          this.$message.error('支持格式：mp4, flv')
          setTimeout(() => {
            reject()
          }, 2000);
        }
        
      })
    },
    videoHandleError() {
      this.count = this.count > 0? this.count - 1: 0
    },
    /***********航线上传***********/
    RouteHandleRemove() {
      this.editForm.routeLineAttachId = ''
    },
    RouteBeforeRemove(file) {
      return new Promise((resolve, reject)=>{
        if (file.status === 'ready') {
          return reject()
        }
        this.$confirm(`${this.$t('ConfirmToDelete')} ${ file.name }${this.$t('questionMark')}`).then(()=>{
          removeFile({
            ids: file.attachId
          }).then(res=>{
            if (res.data.code === 200) {
              resolve()
              this.$message.success(res.data.msg)
            }
            reject()
          }).catch(()=>{
            reject()
          })
        }).catch(()=>{
          reject()
        })
      })
    },
    RouteHandleExceed(files, fileList) {
      this.$message.warning(
        `
          ${this.$t('mission.uploadFileLimitText1')} 
          ${this.routeLimit} 
          ${this.$t('mission.uploadFileLimitText2')} 
          ${files.length} 
          ${this.$t('mission.uploadFileLimitText3')} 
          ${files.length + fileList.length} 
          ${this.$t('mission.uploadFileLimitText4')} 
        `
      );
    },
    RouteHandleSuccess(res, file, fileList) {
      if (res.code === 200) {
        const data = res.data
        const attachId = data.attachId
        for (let i = 0; i < fileList.length; i++) {
          var item = fileList[i]
          if (item.uid === file.uid) {
            file.attachId = attachId
            item.attachId = attachId
          }
        }
        this.editForm.routeLineAttachId = attachId
      } else {
        fileList.pop()
      }
    },
    RouteHandleBeforeUpload(file) {
      if(file.name) {
        var index = file.name.lastIndexOf('.')
        var type = file.name.slice(index, index.length)
        if (type === '.kml' || type === '.json' || type === '.geojson') {
          // this.count++
        } else {
          this.$message.error('支持格式：kml, geojson')
          this.routeFileList = []
          return false
        }
      } else {
        this.$message.error('支持格式：kml, geojson')
        this.routeFileList = []
        return false
      }
    },
    /***********作业区上传***********/
    operationAreaHandleRemove() {
      this.editForm.workAreaAttachId = ''
    },
    operationAreaBeforeRemove(file) {
      return new Promise((resolve, reject)=>{
        if (file.status === 'ready') {
          return reject()
        }
        this.$confirm(`${this.$t('ConfirmToDelete')} ${ file.name }${this.$t('questionMark')}`).then(()=>{
          removeFile({
            ids: file.attachId
          }).then(res=>{
            if (res.data.code === 200) {
              resolve()
              this.$message.success(res.data.msg)
            }
            reject()
          }).catch(()=>{
            reject()
          })
        }).catch(()=>{
          reject()
        })
      })
    },
    operationAreaHandleExceed(files, fileList) {
      this.$message.warning(
        `
          ${this.$t('mission.uploadFileLimitText1')}
          ${this.operationAreaLimit}
          ${this.$t('mission.uploadFileLimitText2')}
          ${files.length} 
          ${this.$t('mission.uploadFileLimitText3')} 
          ${files.length + fileList.length} 
          ${this.$t('mission.uploadFileLimitText4')} 
        `
      );
    },
    operationAreaHandleSuccess(res, file, fileList) {
      if (res.code === 200) {
        const data = res.data
        const attachId = data.attachId
        for (let i = 0; i < fileList.length; i++) {
          var item = fileList[i]
          if (item.uid === file.uid) {
            file.attachId = attachId
            item.attachId = attachId
          }
        }
        this.editForm.workAreaAttachId = data.attachId
      } else {
        fileList.pop()
      }
    },
    operationAreaHandleBeforeUpload(file) {
      if(file.name) {
        var index = file.name.lastIndexOf('.')
        var type = file.name.slice(index, index.length)
        if (type === '.kml' || type === '.json' || type === '.geojson') {
          // this.count++
        } else {
          this.$message.error('支持格式：kml, geojson')
          this.workAreaFileList = []
          return false
        }
      } else {
        this.$message.error('支持格式：kml, geojson')
        this.routeFileList = []
        return false
      }
    },
    // 关闭任务回放弹窗
    closetaskReplayVisible() {
      this.videoForMapList = []
      this.taskReplayVisible = false
    },
    // 视频文件
    handleCloseVideo() {
      this.videoLoadingFileList = []
      this.checkedFileList = []
      this.isIndeterminate = false;
      this.videoDialogVisible = false
    },
    // 视频文件全选
    handleCheckAllChange(val) {
      this.checkedFileList = val ? this.videoLoadingFileList.map(item=>{
        return item.url
      }) : [];
      this.isIndeterminate = false;
    },
    // 
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkFileAll = checkedCount === this.videoLoadingFileList.length? true: false;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.videoLoadingFileList.length;
    },
    // 批量下载
    downLoadAllFile() {
      let As = []
      for (var i = 0; i < this.checkedFileList.length; i++) {
        const url = this.checkedFileList[i]
        As[i] = document.createElement("A");
        As[i].style.display = "none";
        As[i].href = url;
        document.body.append(As[i]);
      }
      for (var i = 0; i < As.length; i++) {
        this.downloadOneByOne(As[i], i)
      }
    },
    // oneByone
    downloadOneByOne(a, i) {
      setTimeout(()=>{
        a.click()
        a.remove()
      }, i * 500)
    },
    // 下载文件
    downloadFile(item) {
      const a = document.createElement("A");
      a.style.display = "none";
      a.href = item.url;
      a.download = item.name + '';
      document.body.append(a);
      a.click();
      setTimeout(() => {
        a.remove();
      }, 500);
    }
  }
};
</script>

<style lang="scss">
  #record-task-box {
    height: 100%;
    box-sizing: border-box;
    padding: 0px;
  }
  .record-task-file-checkbox-item {
    display: flex;
    justify-content: space-between;
    .icon {
      cursor: pointer;
    }
    .file-checkbox-item-icon {
      font-size: 16px;
      color: #409EFF;
      &:hover {
        color: #409EFFAA;
      }
    }
  }
</style>
