<template>
  <basic-container
    id="hanger-task-box-01">
    <!-- crud -->
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      :page.sync="page"
      :permission="permissionList"
      v-model="form"
      ref="crud"
      @row-update="rowUpdate"
      @row-save="rowSave"
      @row-del="rowDel"
      @search-change="searchChange"
      @search-reset="searchReset"
      @selection-change="selectionChange"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot="menuLeft">
        <el-button
          size="small"
          icon="el-icon-plus"
          @click="addTask"
          type="primary"
          >{{ $t("btnAdd") }}</el-button
        >
      </template>
      <template slot="uavId" slot-scope="{ row }">
        <span>{{ row.airPlaneName || "-" }}</span>
      </template>
      <!-- 报警状态 -->
      <template slot="alarmState" slot-scope="{ row }">
        <div class="abnormalCondition" v-if="row.alarmState">有</div>
        <div v-else>无</div>
      </template>
      <!-- 时间组件 -->
      <template slot="mixedTime" slot-scope="scope">
        <div>
          <div>
            <span>{{ scope.row.planBeginTime }} —— </span>
            <span>{{ scope.row.planFinishTime }}</span>
          </div>
          <div>
            <span>{{ scope.row.realBeginTime }} —— </span>
            <span>{{ scope.row.realFinishTime }}</span>
          </div>
        </div>
      </template>
      <!-- 执行方式 -->
      <template
        slot="hangarTaskType"
        slot-scope="scope">
        <div>
          {{
            hangarTaskTypeList.find(item=>{
              return scope.row.hangarTaskType === item.value
            }).label
          }}
        </div>
      </template>
      <!-- 操作栏 -->
      <template slot-scope="scope" slot="menu">
        <!-- 查看 -->
        <el-button
          v-if="arbitrament('view', scope.row)"
          type="text"
          icon="el-icon-view"
          size="small"
          @click="viewDetail(scope.row)"
        >
          查看
        </el-button>
        <!-- 结束任务 -->
        <el-button
          v-if="arbitrament('endTask', scope.row)"
          type="text"
          icon="el-icon-s-release"
          size="small"
          @click="endTask(scope.row)"
        >
          结束任务
        </el-button>
        <!-- 取消任务 -->
        <el-button
          :ref="'cancelTaskBtn' + scope.row.id"
          v-if="arbitrament('cancelTask', scope.row)"
          type="text"
          icon="el-icon-error"
          size="small"
          @click="cancelTask(scope.row, 'cancelTaskBtn' + scope.row.id)"
        >
          取消任务
        </el-button>
        <!-- 视频回放 -->
        <el-button
          v-if="arbitrament('videoReplay', scope.row)"
          type="text"
          icon="el-icon-video-camera-solid"
          size="small"
          @click="viewVideoDetail(scope.row)"
        >
          视频回放
        </el-button>
        <!-- 轨迹回放 -->
        <el-button
          v-if="arbitrament('trackReplay', scope.row)"
          type="text"
          icon="el-icon-discover"
          size="small"
          @click="viewDetail(scope.row)"
        >
          轨迹回放
        </el-button>
        <!-- 下载报告 -->
        <el-button
          v-if="arbitrament('download', scope.row)"
          type="text"
          icon="el-icon-download"
          size="small"
          @click="downLoadReport(scope.row)"
        >
          下载报告
        </el-button>
         <!-- 手动续飞 -->
         <!-- <el-button
          v-if="arbitrament('continuing', scope.row)"
          type="text"
          icon="el-icon-position"
          size="small"
          @click="continuing(scope.row)"
        >
          手动续飞
        </el-button> -->
        <!-- 删除  -->
        <el-button
          v-if="arbitrament('del', scope.row)"
          :ref="'removeBtn' + scope.row.id"
          type="text"
          icon="el-icon-delete"
          size="small"
          @click="rowDel(scope.row, 'removeBtn' + scope.row.id)"
        >
          删除
        </el-button>
        <!-- 推流地址 -->
        <el-dropdown
          style="display: block"
          @command="onCopy"
          class="copy-btn"
          placement="bottom"
          v-if="arbitrament('stream', scope.row)"
        >
          <span class="copy-btn">
            <el-button type="text" size="small"> 复制推流地址 </el-button>
            <i class="el-icon-arrow-down task-detail-drodown"></i>
          </span>
          <el-dropdown-menu
            v-if="
              scope.row.liveVideoVOList &&
              Array.isArray(scope.row.liveVideoVOList)
                ? true
                : false
            "
            slot="dropdown"
          >
            <el-dropdown-item
              :command="[item, 'push']"
              v-for="item in scope.row.liveVideoVOList
                ? scope.row.liveVideoVOList
                : []"
              :key="item.id"
            >
              {{ item.liveDevice }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 拉流地址 -->
        <el-dropdown
          style="display: block"
          @command="onCopy"
          class="copy-btn"
          placement="bottom"
          v-if="arbitrament('stream', scope.row)"
        >
          <span class="copy-btn">
            <el-button type="text" size="small"> 复制播放地址 </el-button>
            <i class="el-icon-arrow-down task-detail-drodown"></i>
          </span>
          <el-dropdown-menu
            v-if="
              scope.row.liveVideoVOList &&
              Array.isArray(scope.row.liveVideoVOList)
                ? true
                : false
            "
            slot="dropdown"
          >
            <el-dropdown-item
              :command="[item, 'pull']"
              v-for="item in scope.row.liveVideoVOList
                ? scope.row.liveVideoVOList
                : []"
              :key="item.id"
            >
              {{ item.liveDevice }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 执行 -->
        <el-button
          v-if="arbitrament('execute', scope.row)"
          :ref="'executeTask' + scope.row.id"
          type="text"
          icon="el-icon-position"
          size="small"
          @click="executeTask(scope.row, 'executeTask' + scope.row.id)"
        >
          {{ $t("execute") }}
        </el-button>
      </template>
      <!-- 执行状态 -->
      <template slot-scope="scope" slot="taskStatusName">
        <el-tag
          :type="
            scope.row.taskStatus == 3? 'success' :  (
              scope.row.taskStatus == 4? '': (
                scope.row.taskStatus == 5? 'warning': (
                  scope.row.taskStatus == 6? 'danger': 'info'
                )
              )
            )
          "
        >
          {{ statusMap[scope.row.taskStatus] }}
        </el-tag>
      </template>
    </avue-crud>
    <!-- 轨迹回放 -->
    <DetailDialog
      v-if="detailVisible"
      :dialogData="dialogData"
      :dialogVisible="detailVisible"
      @closeDialog="closeDetail"
    />
    <!-- 视频回访 -->
    <VideoDialog
      v-if="videoHistoryDialogVisible"
      :taskPlayBackData="taskPlayBackData"
      :videoDialogData="videoDialogData"
      :videoDialogVisible="videoHistoryDialogVisible"
      @videoCloseDialog="videoCloseDialog"
    />
    <!-- 新增弹窗 -->
    <el-dialog
      :title="$t('addTask')"
      :visible.sync="editDialogVisible"
      width="900px"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleCloseEdit">
      <el-form
        :model="editForm"
        :rules="rules"
        ref="editForm"
        label-width="auto"
        inline
        class="demo-ruleForm">
        <!-- 任务名称 -->
        <el-form-item
          :label="`${$t('mission.name')}：`"
          prop="taskName">
          <el-input
            style="width: 248px"
            v-model="editForm.taskName"
            size="small"
            :placeholder="$t('inputPlease')"
          >
          </el-input>
        </el-form-item>
        <!-- 选择品牌 -->
        <el-form-item
            :label="`${$t('mission.brand')}：`"
            prop="manufacturerId">
          <select-tree
            v-model="editForm.manufacturerId"
            :data="manufacturerList"
            size="small"
            :clearable="true"
            style="width: 248px"
            @clear="manufacturerClear"
            @change="manufacturerChange"
            :placeholder="$t('selectPlease')"
          >
          </select-tree>
        </el-form-item>
        <!-- 使用机库 -->
        <el-form-item
          :label="`${$t('mission.usingHangar')}：`"
          prop="hangarID">
          <select-tree
            v-model="editForm.hangarID"
            :data="hangarList"
            size="small"
            :clearable="true"
            :disabled="!editForm.manufacturerId"
            style="width: 248px"
            @clear="hangerClear"
            @change="hangerChange"
            :placeholder="$t('selectPlease')"
          >
          </select-tree>
        </el-form-item>
        <!-- 使用航线 -->
        <el-form-item
            :label="`${$t('mission.usingRoute')}：`"
            prop="routeId">
          <select-tree
            v-model="editForm.routeId"
            :data="taskRouteList"
            :clearable="true"
            :disabled="!editForm.hangarID"
            size="small"
            style="width: 178px"
            :placeholder="$t('selectPlease')"
          >
          </select-tree>
          <el-button
            type="primary"
            style="margin-left: 5px; padding: 8px 7px"
            icon="el-icon-view"
            :disabled="!editForm.hangarID"
            size="small"
            @click="showRoute"
          >
            {{ $t("mission.viewRoute") }}
          </el-button>
        </el-form-item>
        <!-- 机库任务类型 -->
        <el-form-item
          :label="`${$t('mission.hangarTaskType')}：`"
          prop="hangarTaskType"
        >
          <select-tree
            :disabled="true"
            :showSearch="false"
            :data="hangarTaskTypeList"
            style="width: 248px"
            size="small"
            :placeholder="$t('selectPlease')"
            v-model="editForm.hangarTaskType"
          >
          </select-tree>
        </el-form-item>
        <!-- 执行时间 -->
        <el-form-item
          :label="`${$t('mission.executionTime')}：`"
          prop="executionTime"
        >
          <el-time-picker
            v-model="editForm.executionTime"
            size="small"
            value-format="HH:mm:ss"
            style="width: 248px"
            :placeholder="$t('selectPlease')"
          >
          </el-time-picker>
        </el-form-item>
        <!-- 任务周期 -->
        <el-form-item
          :label="`${$t('mission.taskCycle')}：`"
          prop="scheduleTimeList"
        >
          <el-input
            :disabled="true"
            style="width: 178px"
            v-model="editForm.scheduleTimeList"
            size="small"
            :placeholder="$t('mission.cycleByClick')"
          >
          </el-input>
          <el-button
            type="primary"
            style="margin-left: 5px; padding: 8px 7px"
            size="small"
            @click="openCalendar"
          >
            {{ $t("mission.choseDate") }}
          </el-button>
        </el-form-item>
        <!-- 使用飞机 -->
        <el-form-item
          :label="`${$t('mission.usingUAV')}：`"
          prop="equipmentName"
        >
          <el-input
            :disabled="true"
            style="width: 248px"
            v-model="editForm.equipmentName"
            size="small"
            :placeholder="$t('inputPlease')"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="handleCloseEdit">{{
          $t("btnCancel")
        }}</el-button>
        <el-button
          size="small"
          v-loading="submitLoading"
          type="primary"
          @click="submitTask"
          >{{ $t("btnOk") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 日历 -->
    <el-dialog
      :title="$t('mission.chooseTaskCycle')"
      :visible.sync="calendarDialogVisible"
      width="900px"
      top="30px"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-calendar>
        <template slot="dateCell" slot-scope="{ data }">
          <div
            class="calendar-cell"
            @click="selectedDate(data)"
            style="height: 100%"
          >
            <div class="calendar-cell-title">
              {{ data.day.split("-")[2] }}
              {{ editForm.scheduleTimeList.indexOf(data.day) > -1 ? "✔️" : "" }}
            </div>
            <div class="calendar-cell-context">
              <el-tooltip
                class="item"
                effect="dark"
                :content="calendarContext(data)"
                placement="right"
              >
                <div>
                  {{ calendarContext(data) }}
                </div>
              </el-tooltip>
            </div>
          </div>
        </template>
      </el-calendar>
      <span slot="footer">
        <el-button size="small" @click="clearDate">{{
          $t("emptyBtnText")
        }}</el-button>
        <el-button size="small" type="primary" @click="submitCalendar">{{
          $t("btnOk")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 机库航线 -->
    <hangar-route-dialog
      @handleClose="hangarRouteDialogClose"
      @submit="chooseHangarRoute"
      :routeList="taskRouteList"
      :dialogVisible.sync="hangarRouteDialogVisible"
    />
  </basic-container>
</template>

<script>
import {
  getList,
  add,
  update,
  remove,
  getHistoryVideo,
  endTask,
  cancelHangarTask,
  executeHangarManualTask
} from "@/api/phugiacloud/projectTask";
import {
  getHangarByManufacturerId,
  selectRouteListByManufacturerId,
  getHangarScheTaskList
} from "@/api/hangar/index";
import { getBrandList } from "@/api/uav/brandManagement";
import { download } from "@/api/alarm/identify-data";
import { detail } from "@/api/phugiacloud/operatorTask";
import { mapGetters } from "vuex";
import { getToken } from "@/util/auth";
import website from "@/config/website";
import FormLayer from "@/components/form-layer/index";
import DetailDialog from "@/components/task-detail-dialog";
import VideoDialog from "./components/task-video-dialog";
import hangarRouteDialog from "./components/route-dialog";

export default {
  name: "projecTask",
  components: {
    FormLayer,
    DetailDialog,
    VideoDialog,
    hangarRouteDialog
  },
  data() {
    return {
      // 编辑弹窗
      editDialogVisible: false,
      // 编辑表单
      editForm: {
        // 任务名称
        taskName: "",
        // 品牌ID
        manufacturerId: '',
        // 航线类型
        taskType: 2,
        // 航线
        routeId: "",
        // 机库任务类型
        hangarTaskType: 2,
        // 执行时间
        executionTime: "",
        // 执行周期
        scheduleTimeList: [],
        // 使用机库
        hangarID: "",
        // 使用飞机
        equipmentName: "",
        // 机库序列号
        hangarSN: "",
        // 无人机ID
        airPlaneID: "",
      },
      // 效验规则
      rules: {
        taskName: [
          { required: true, message: this.$t("inputPlease"), trigger: "blur" },
        ],
        manufacturerId: [
          { required: true, message: this.$t("inputPlease"), trigger: "blur" },
        ],
        taskType: [
          { required: true, message: this.$t("inputPlease"), trigger: "blur" },
        ],
        routeId: [
          { required: true, message: this.$t("inputPlease"), trigger: "blur" },
        ],
        hangarTaskType: [
          { required: true, message: this.$t("inputPlease"), trigger: "blur" },
        ],
        executionTime: [
          { required: true, message: this.$t("inputPlease"), trigger: "blur" },
        ],
        scheduleTimeList: [
          { required: true, message: this.$t("inputPlease"), trigger: "blur" },
        ],
        hangarID: [
          { required: true, message: this.$t("inputPlease"), trigger: "blur" },
        ],
        equipmentName: [
          { required: true, message: this.$t("inputPlease"), trigger: "blur" },
        ],
      },
      // 新增提交按钮
      submitLoading: false,
      // 任务类型列表
      taskTypeList: [
        {
          label: "飞行任务",
          value: 1,
        },
        {
          label: "机库任务",
          value: 2,
        },
      ],
      // 机库航线弹窗
      hangarRouteDialogVisible: false,
      // 使用航线列表
      taskRouteList: [],
      // 飞手列表
      operatorList: [],
      // 机库任务类型
      hangarTaskTypeList: [
        {
          label: "手动任务",
          value: 1,
        },
        {
          label: "定期任务",
          value: 2,
        },
      ],
      // 使用机库列表
      hangarList: [],
      // 日历弹窗
      calendarDialogVisible: false,
      // 查看历史轨迹
      detailVisible: false,
      // 机库任务日历表
      hangerTaskCalendarList: [],
      // 无人机ID列表
      airPlaneIDList: [],
      // 品牌列表
      manufacturerList: [],
      // 历史回放
      videoHistoryDialogVisible: false,
      // 状态字典
      statusMap: {
        1: "待执行",
        2: "已领取",
        3: "执行中",
        4: "已完成",
        5: "已取消",
        6: "执行失败",
        7: '已过期'
      },
      headers: {
        "Blade-Auth": "bearer " + getToken(),
        Authorization: `Basic ${Base64.encode(
          `${website.clientId}:${website.clientSecret}`
        )}`,
      },
      form: {
        projectId: null,
        teamId: null,
        equipmentIds: [],
        equipmentNames: [],
        timeRange: [],
        taskTimeRange: [],
      },
      query: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      dialogData: {},
      selectionList: [],
      data: [],
      // 视屏回放当前行数据
      taskPlayBackData: {}
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.projectTask_add, false),
        viewBtn: this.vaildData(this.permission.projectTask_view, false),
        delBtn: this.vaildData(this.permission.projectTask_delete, false),
        editBtn: this.vaildData(this.permission.projectTask_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    projectId() {
      return this.$route.query.projectId;
    },
    teamId() {
      return this.$route.query.teamId;
    },
    option() {
      let statusList = [];
      for (const key in this.statusMap) {
        statusList.push({
          label: this.statusMap[key],
          value: key,
        });
      }
      return {
        height: 'auto',
        calcHeight: 110,
        tip: false,
        searchShow: true,
        searchMenuSpan: 4,
        border: true,
        index: true,
        viewBtn: false, //重写查看按钮，自带的不要
        selection: true,
        dialogClickModal: false,
        excelBtn: false,
        columnBtn: false,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        cellBtn: false,
        excelBtn: false,
        labelWidth: 110,
        menuFixed: "right",
        searchBtnText: "搜索",
        column: [
          // 任务名称
          {
            label: "任务名称",
            prop: "taskName",
            width: 120,
            searchSpan: 5,
            search: true,
          },
          // 任务类型
          {
            label: "任务类型",
            prop: "taskType",
            width: 80,
            formatter: (row, value, label) => {
              var node = this.taskTypeList.find((item) => {
                return item.value === value;
              });
              return node && node.label ? node.label : "--";
            },
          },
          // 飞行器
          {
            display: false,
            label: "飞行器",
            prop: "uavId",
            width: 120,
            slot: true,
          },
          // 执行状态（搜索）
          {
            label: "执行状态",
            prop: "taskStatus",
            searchSpan: 5,
            search: true,
            hide: true,
            addDisplay: false,
            editDisplay: false,
            type: "select",
            dicData: statusList,
            showColumn: false,
            rules: [
              {
                required: true,
                message: "请选择项目状态",
                trigger: "blur",
              },
            ],
          },
          // 执行状态
          {
            label: "执行状态",
            prop: "taskStatusName",
            display: false,
            slot: true,
            width: 100,
          },
          // 执行方式
          {
            label: "执行方式",
            prop: "hangarTaskType",
            display: false,
            slot: true,
            width: 100,
          },
          // 计划/实际执行时间
          {
            label: "计划/实际执行时间",
            prop: "mixedTime",
            span: 24,
            width: 280,
            display: false,
          },
          // 实际飞行时长
          {
            label: "实际飞行时长",
            prop: "flyTime",
            searchSpan: 10,
            width: 120,
            display: false,
            formatter: (val, value, label) => {
              var hours = Math.floor(value / 3600);
              var minutes = Math.floor((value - hours * 3600) / 60);
              var remainingSeconds = value - hours * 3600 - minutes * 60;

              if (hours < 10) {
                hours = "0" + hours;
              }

              if (minutes < 10) {
                minutes = "0" + minutes;
              }

              if (remainingSeconds < 10) {
                remainingSeconds = "0" + remainingSeconds;
              }

              return hours + "h" + minutes + "m" + remainingSeconds + "s";
            },
          },
          // 实际飞行里程
          {
            label: "实际飞行里程",
            prop: "flyDistance",
            searchSpan: 10,
            display: false,
            formatter: (val, value, label) => {
              if (!value) return "0km";
              return (value / 1000).toFixed(2) + "km";
            },
          },
          // 有无报警
          {
            prop: "alarmState",
            label: "有无报警",
            slot: true,
            search: false,
            display: false,
          },
          // 所属团队
          {
            label: "所属团体",
            width: 120,
            prop: "teamId",
            type: "tree",
            dataType: "number",
            dicUrl: "/api/blade-system/dept/tree",
            props: {
              label: "title",
              value: "id",
            },
            rules: [
              {
                required: true,
                message: "请选择任务所属团体",
                trigger: "blur",
              },
            ],
          },
          // 状态说明
          {
            prop: "statusDesc",
            label: "状态说明",
            width: 200,
          },
          // 任务编号
          {
            label: "任务编号",
            prop: "id",
            width: 160,
            display: false,
          },
        ]
      };
    }
  },
  mounted() {
    this.getBrandList()
    this.$EventBus.$off("mission-canter-taskpage");
    this.$EventBus.$on("mission-canter-taskpage", (value) => {
      if (value && Array.isArray(value)) {
        this.airPlaneIDList = value;
        this.onLoad(this.page);
      }
    });
  },
  methods: {
    // 增加任务
    addTask() {
      this.editDialogVisible = true;
    },
    // 关闭编辑弹窗
    handleCloseEdit() {
      this.$refs.editForm.resetFields();
      this.editDialogVisible = false;
    },
    // 获取机库
    getHangarByManufacturerId(id) {
      getHangarByManufacturerId({
        manufacturerId: id
      }).then((res) => {
        if (res.data.code === 200) {
          this.hangarList = res.data.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
              equipmentName: item.equipmentName,
              airPlaneID: item.equipmenId,
              hangarSN: item.serialNumber,
            };
          });
        }
      });
    },
    // 获取品牌列表
    getBrandList() {
        getBrandList().then(res=>{
        if (res.data.code === 200) {
            this.manufacturerList = res.data.data.records.map(item=>{
                return {
                    label: item.name,
                    value: item.id
                }
            })
        }
      })
    },
    // 获取Uver航线
    selectRouteListByManufacturerId(params) {
      selectRouteListByManufacturerId(params).then((res) => {
        if (res.data.code === 200) {
          this.taskRouteList = res.data.data.map((item) => {
            return {
              value: item.id,
              label: item.name,
              geom: item.geom
            };
          });
        }
      });
    },
    // 下载Excel
    downLoadTask() {
      this.$refs.crud.rowExcel();
    },
    continuing(row) {
      console.log(row);
    },
    // 提交新增任务
    submitTask() {
      this.$refs.editForm.validate((value) => {
        if (value) {
          this.submitLoading = true;
          let data = Object.assign(this.editForm, {
            projectId: this.projectId,
          });
          add(data)
            .then((res) => {
              if (res.data.code === 200) {
                this.$message.success(res.data.msg);
                this.handleCloseEdit();
                this.onLoad(this.page);
              }
            })
            .finally(() => {
              this.submitLoading = false;
            });
        }
      });
    },
    // 打开日历
    openCalendar() {
      if (this.editForm.hangarID) {
        getHangarScheTaskList()
          .then((res) => {
            if (res.data.code === 200) {
              this.hangerTaskCalendarList =
                res.data.data && Array.isArray(res.data.data)
                  ? res.data.data
                  : [];
              this.calendarDialogVisible = true;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((res) => {
            this.$message.error(this.$t("mission.cycleTaskFailure"));
          });
      } else {
        this.$message.warning(this.$t("mission.pleaseUsingHanger"));
      }
    },
    // 关闭日历
    handleCloseCalendar() {
      this.hangerTaskCalendarList = [];
      this.editForm.scheduleTimeList = [];
      this.calendarDialogVisible = false;
    },
    calendarContext(data) {
      for (var i = 0; i < this.hangerTaskCalendarList.length; i++) {
        let item = this.hangerTaskCalendarList[i];
        const planBeginDate = item.planBeginTime.split(" ")[0];
        if (planBeginDate === data.day) {
          return `${item.hangarName} -- ${item.trackName} -- ${
            item.planBeginTime.split(" ")[1]
          }`;
        }
      }
      return "";
    },
    // 选择日期
    selectedDate(data) {
      let day = data.day;
      if (this.editForm.scheduleTimeList.indexOf(day) > -1) {
        this.editForm.scheduleTimeList = this.editForm.scheduleTimeList.filter(
          (item) => {
            return item !== day;
          }
        );
      } else {
        this.editForm.scheduleTimeList.push(day);
      }
    },
    // 清空任务周期
    clearDate() {
      this.editForm.scheduleTimeList = [];
    },
    // 确定任务周期
    submitCalendar() {
      this.calendarDialogVisible = false;
    },
    // 查看机库航线
    showRoute() {
        this.hangarRouteDialogVisible = true
    },
    // 关闭查看机库航线
    hangarRouteDialogClose() {
        this.hangarRouteDialogVisible = false
    },
    // 选择机库航线
    chooseHangarRoute(v) {
        this.editForm.routeId = v
    },
    // 操作栏显示
    arbitrament(type, row) {
      /*
        1、手动执行
          执行方式：手动执行；状态1：待执行；操作按钮：执行；
          执行方式：手动执行；状态3：执行中；操作按钮：查看、结束任务；
          执行方式：手动执行；状态4：已完成；操作按钮：任务回放（视频回放、轨迹回放）、下载报告、删除；
          执行方式：手动执行；状态6：执行失败；操作按钮：任务回放（视频回放、轨迹回放）、手动续飞、删除；
        2、定期执行
          执行方式：定期执行；状态1：待执行；操作按钮：查看、取消；
          执行方式：定期执行；状态3：执行中；操作按钮：查看、结束任务；
          执行方式：定期执行；状态4：已完成；操作按钮：任务回放（视频回放、轨迹回放）、下载报告、删除；
          执行方式：定期执行；状态5：已取消；操作按钮：删除；
          执行方式：定期执行；状态6：执行失败；操作按钮：任务回放（视频回放、轨迹回放）、手动续飞、删除；
      */
      const { taskStatus, hangarTaskType } = row;
      const btnMap = {
        // 机库任务
        1: {
          // 手动任务
          1: { execute: true }, // 待执行
          2: {}, // 已领取
          3: { view: true, endTask: true}, // 执行中
          4: { trackReplay: true, videoReplay: true, download: true, del: true }, // 已完成
          5: {}, // 已取消
          6: { trackReplay: true, videoReplay: true, continuing: true, del: true }, // 执行失败
        },
        2: {
          // 定期任务
          1: { view: true, cancelTask: true }, // 待执行
          2: {}, // 已领取
          3: { view: true, endTask: true}, // 执行中
          4: { trackReplay: true, videoReplay: true, del: true, download: true }, // 已完成
          5: { del: true }, // 已取消
          6: { trackReplay: true, videoReplay: true, continuing: true, del: true }, // 执行失败
        },
      };
      if ( btnMap[hangarTaskType] && btnMap[hangarTaskType][taskStatus]) {
        return btnMap[hangarTaskType][taskStatus][type]
          ? true
          : false;
      }
      return false;
    },
    // 复制推拉流地址
    onCopy(arr) {
      let [node, type] = arr;
      const text =
        type === "push" ? node.pushStreamUrl : node.webrtcPullStreamUrl;
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            this.$message.success("复制成功");
          })
          .catch(() => {
            this.$message.error("复制失败");
          });
      } else {
        // IE
        try {
          var textarea = document.createElement("textarea");
          textarea.style.position = "fixed";
          textarea.style.opacity = 0;
          textarea.value = text;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand("copy");
          document.body.removeChild(textarea);
          this.$message.success("复制成功");
        } catch (error) {
          this.$message.error("复制失败，建议使用chrome浏览器");
        }
      }
    },
    // 视频回放
    viewVideoDetail(row) {
      getHistoryVideo(row.id).then((res) => {
        this.taskPlayBackData = row;
        this.videoDialogData = res.data.data;
        if (res.data.code == 200) {
          if (this.hasVideoURL(this.videoDialogData)) {
            this.videoHistoryDialogVisible = true;
            return;
          }
          if (!this.videoHistoryDialogVisible) {
            this.$message({
              message: "此任务暂无视频回放，请核实",
              type: "warning",
            });
          }
        } else {
          this.$message({
            message: "此任务暂无视频回放，请核实",
            type: "warning",
          });
        }
      });
    },
    // 是否有视频地址
    hasVideoURL(data) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (Array.isArray(item.historyVideoVOList)) {
          let urlList = item.historyVideoVOList;
          for (let j = 0; j < urlList.length; j++) {
            return true;
          }
        }
      }
      return false;
    },
    // 品牌列表
    manufacturerChange(value) {
        var node = this.manufacturerList.find((item) => {
            return value === item.value;
        });
        this.hangerClear()
        if (node) {
            this.getHangarByManufacturerId(node.value)
        }
    },
    // 品牌清空
    manufacturerClear(v) {
      this.editForm.manufacturerId = '';
      this.editForm.hangarID = '';
      this.editForm.routeId = '';
      this.hangarList = []
      this.taskRouteList = [];
      this.editForm.equipmentName = '';
      this.editForm.airPlaneID = '';
      this.editForm.hangarSN = '';
    },
    // 机库下拉选择
    hangerChange(value) {
      var node = this.hangarList.find((item) => {
        return value === item.value;
      });
      this.editForm.routeId = [];
      this.taskRouteList = [];
      if (node) {
        this.editForm.equipmentName = node.equipmentName;
        this.editForm.airPlaneID = node.airPlaneID;
        this.editForm.hangarSN = node.hangarSN;
        var manufacturer = this.manufacturerList.find((item) => {
            return this.editForm.manufacturerId === item.value;
        });
        this.selectRouteListByManufacturerId({
          manufacturerId: manufacturer.value,
          hangarId: value
        });
      }
    },
    // 清空机库选择器
    hangerClear() {
      this.editForm.hangarID = '';
      this.editForm.routeId = '';
      this.taskRouteList = [];
      this.editForm.equipmentName = '';
      this.editForm.airPlaneID = '';
      this.editForm.hangarSN = '';
    },
    // 设置选择时间
    setTime(value) {
      if (value) {
        this.form.planBeginTime = value[0].getTime();
        this.form.planFinishTime = value[1].getTime();
      } else {
        this.form.planBeginTime = null;
        this.form.planFinishTime = null;
      }
    },
    // 下载报告
    downLoadReport(row) {
      this.$confirm("是否下载该报告?", "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        download(row.id).then((r) => {
          let index = r.headers["content-disposition"].indexOf("=") + 1;
          let fileName = decodeURIComponent(
            r.headers["content-disposition"].substr(index)
          );
          const blob = r.data;
          let link = document.createElement("a");
          link.href = URL.createObjectURL(
            new Blob([blob], {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            })
          );
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
        });
      });
    },
    // 查看详情
    viewDetail(row, status) {
      const { groundStationType } = row;
      const params = {
        id: row.id,
      };
      detail(params).then((res) => {
        if (res.data.code === 200) {
          const { pageType } = this.$route.query;
          this.dialogData = Object.assign(res.data.data, {
            pageType,
            groundStationType,
          });
          this.detailVisible = true;
        }
      });
      // done()
    },
    // 结束任务
    endTask(row) {
      endTask({ id: row.id }).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.onLoad(this.page);
        }
      });
    },
    // 取消任务
    cancelTask(row, refName) {
      this.$refs[refName].loading = true;
      cancelHangarTask({ id: row.id })
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
            this.onLoad(this.page);
          }
        })
        .finally(() => {
          this.$refs[refName].loading = false;
        });
    },
    // 执行任务
    executeTask(row, refName) {
      this.$refs[refName].loading = true;
      executeHangarManualTask({ id: row.id })
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success(this.$t("mission.executeTask"));
            this.onLoad(this.page);
          }
        })
        .finally(() => {
          this.$refs[refName].loading = false;
        });
    },
    // 关闭轨迹回放
    closeDetail() {
      const { from } = this.$route.query;
      this.detailVisible = false;
      if (from === "detail") {
        // 跳转路由
        this.$router.push("/mission-center/item-manager-config");
        // 通知首页切换到项目列表
        this.$EventBus.$emit("toProjectList");
      }
    },
    // 关闭视频回放
    videoCloseDialog(done) {
      this.videoHistoryDialogVisible = false;
      done();
    },
    rowSave(row, done, loading) {
      add({
        ...row,
        projectId,
      }).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          window.console.log(error);
        }
      );
    },
    rowUpdate(row, index, done, loading) {
      update(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          console.log(error);
        }
      );
    },
    rowDel(row, refName) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$refs[refName].loading = true;
        remove(row.id)
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success(res.data.msg);
              this.onLoad(this.page);
            }
          })
          .finally(() => {
            this.$refs[refName].loading = false;
          });
      });
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      if (this.form.taskTimeRange && this.form.taskTimeRange.length) {
        params = {
          ...params,
          startTime: this.form.taskTimeRange[0],
          endTime: this.form.taskTimeRange[1],
        };
      }
      params.projectId = this.projectId;
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      this.query.projectId = this.projectId;
      params.taskType = 2;
      params.taskBackgroundRequest = true;
      params.airPlaneIDList = this.airPlaneIDList.join(",");
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
        this.$nextTick(() => {
          this.$refs.crud.doLayout();
        });
      });
    }
  }
};
</script>

<style lang="scss">
#hanger-task-box-01 {
  height: 100%;
  box-sizing: border-box;
  padding: 0px;
}
.route-input {
  position: relative;

  &:hover {
    .route-input--delete {
      visibility: visible;
    }

    .route-input--deletehis {
      visibility: visible;
    }
  }

  &--delete {
    visibility: hidden;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 9;
  }

  &--deletehis {
    visibility: hidden;
    position: absolute;
    top: 14px;
    right: 10px;
    cursor: pointer;
    z-index: 9;
  }
}
.snapshoot {
  min-height: 200px;
  max-height: 580px;
  overflow: scroll;

  & > img {
    width: 100%;
    display: block;
  }

  .avue-empty__image {
    display: flex;
    justify-content: center;
  }
}
.abnormalCondition {
  text-align: center;
  width: 55px;
  height: 24px;
  border-radius: 12px;
  color: red;
  background-color: rgba(255, 0, 0, 0.3);
  margin: 0 auto;
}
.task-detail-drodown {
  color: #409eff;
}
.calendar-cell {
  display: flex;
  flex-direction: column;
  .calendar-cell-title {
  }
  .calendar-cell-context {
    font-size: 12px;
    height: 50px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* 显示的行数，可以根据需要修改 */
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
