var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    { staticClass: "project-task-box" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                lazy: true,
                label: _vm.$t("FilghtTask"),
                name: _vm.$t("FilghtTask"),
              },
            },
            [_c("task")],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                lazy: true,
                label: _vm.$t("mission.hangarTask"),
                name: _vm.$t("mission.hangarTask"),
              },
            },
            [_c("hangarTask")],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                lazy: true,
                label: _vm.$t("mission.taskRecord"),
                name: _vm.$t("mission.taskRecord"),
              },
            },
            [_c("record")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }