<template>
  <el-dialog
    class="hangar-route-dialog-2024-8-12"
    :title="$t('mission.missionRoute')"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="1014px"
    :before-close="handleRouteClose">
    <div class="hangar-route-box">
      <div class="hangar-route-map-box">
        <div v-if="dialogVisible" id="cesiumContainer_hangar_route">
          <div class="hangar-route-tabs">
            <div
              class="tabs-item"
              v-for="item in routeList"
              :class="item.value===value? 'tabs-item-active': ''"
              @click="itemClick(item)"
              :key="item.value">
              <span class="tabs-item-checked">
                <i v-if="item.value===value" class="el-icon-check"></i>
              </span>
              <span class="tabs-item-label">{{ item.label }}</span>
            </div>
          </div>
          <div class="hangar-route-panel">
            <div class="route-panel-line">
              <div>航线长度</div>
              <div>--</div>
            </div>
            <div class="route-panel-line">
              <div>航点</div>
              <div>--</div>
            </div>
            <div class="route-panel-line">
              <div>预计执行时间</div>
              <div>--</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleRouteClose">{{ $t("btnClose") }}</el-button>
      <el-button size="small" type="primary" @click="submit">{{ $t("btnOk") }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Map3D from "@/components/cesium/js/Map3d";
import BaseLayer from "@/components/cesium/js/BaseLayer";
import RouteMixin from "@/components/cesium/mixins/route"

export default {
  name: 'HangarRouteDialog',
  mixins: [ RouteMixin ],
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false
      }
    },
    routeList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    dialogVisible(value) {
      if (value) {
        this.$nextTick(()=>{
          this.initMap()
        })
      } else {
        this.value = ''
        this.$options.map3d = null
      }
    },
    routeList(value) {
      this.value = '';
    }
  },
  data(){
    return {
      value: ''
    }
  },
  methods: {
    handleRouteClose() {
      this.$emit('handleClose')
    },
    submit() {
      if (!this.value) {
        return this.$message.warning('请选择航线')
      }
      this.$emit('submit', this.value)
      this.handleRouteClose()
    },
    itemClick(v) {
      this.value = v.value
      let node = this.$options.routeLayer.getNodeById(this.value)
      if (node) {
        this.$options.map3d.zoomTo(
          node._entity,
          new Cesium.HeadingPitchRange(0, -90, 2000)
        );
      }
    },
    initMap() {
      this.$options.map3d = new Map3D("cesiumContainer_hangar_route", {
        sceneMode: Cesium.SceneMode.SCENE3D
      });
      this.initRoute(this.$options.map3d)
      for(var i = 0; i < this.routeList.length; i++){
        const item = this.routeList[i]
        const geom = item.geom
        this.addRoute({
          id: item.value,
          name: item.label,
          geom,
          canEidt: false
        })
      }
    }
  }
}
</script>

<style lang="scss">
.hangar-route-dialog-2024-8-12 {
  font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI;
  .hangar-route-box {
    height: 516px;
    .hangar-route-map-box {
      height: 100%;
      box-sizing: border-box;
      width: 100%;
      #cesiumContainer_hangar_route {
        width: 100%;
        height: 100%;
        position: relative;
        .hangar-route-tabs {
          padding-top: 8px;
          position: absolute;
          height: 100%;
          left: 0px;
          box-sizing: border-box;
          width: 138px;
          z-index: 10;
          background-color: #0C0C0CB2;
          .tabs-item{
            cursor: pointer;
            padding: 8px 15px;
            user-select: none;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            &:hover{
              color: #409EFF;
            }
            &:hover .tabs-item-checked{
              border: 1px #409EFF solid;
            }
            .tabs-item-checked {
              width: 14px;
              height: 14px;
              border: 1px #FFFFFF solid;
              border-radius: 2px;
              font-size: 12px;
              text-align: center;
              line-height: 14px;
              box-sizing: border-box;
            }
            .tabs-item-label {
              width: calc(100% - 16px);
              font-size: 14px;
              line-height: 14px;
              text-align: left;
              padding-left: 8px;
              box-sizing: border-box;
            }
          }
          .tabs-item-active{
            color: #FFFFFF;
            background-color: #409EFF;
            &:hover{
              color: #FFFFFF !important;
            }
            &:hover .tabs-item-checked{
              border: 1px #FFFFFF solid !important;
            }
          }
        }
        .hangar-route-panel {
          color: #FFFFFF;
          font-weight: bold;
          font-size: 14px;
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translateX(-50%);
          height: 86px;
          width: 310px;
          z-index: 10;
          background-color: #0C0C0CB2;
          display: flex;
          justify-content: space-around;
          .route-panel-line {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          }
        }
      }
    }
  }
  .el-dialog__header {
    text-align: center;
    padding-top: 26px;
    padding-bottom: 26px;
    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
      color: #333333;
    }
  }
  .el-dialog__body {
    padding: 0px 20px;
  }
  .el-dialog__footer {
    padding: 24px 20px;
  }
}
</style>