<template>
  <basic-container class="project-task-box-01">
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      :page.sync="page"
      :permission="permissionList"
      :before-open="beforeOpen"
      v-model="form"
      ref="crud"
      @row-del="rowDel"
      @search-change="searchChange"
      @search-reset="searchReset"
      @selection-change="selectionChange"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
          size="small"
          icon="el-icon-plus"
          @click="addTask"
          type="primary">
          {{ $t("btnAdd") }}
        </el-button>
      </template>
      <!-- 飞行器 -->
      <template slot="uavId" slot-scope="{ row }">
        <span>{{ row.airPlaneName || "-" }}</span>
      </template>
      <!-- 执行状态 -->
      <template slot-scope="scope" slot="taskStatusName">
        <el-tag
          :type="
            scope.row.taskStatus == 3? 'success' :  (
              scope.row.taskStatus == 4? '': (
                scope.row.taskStatus == 5? 'warning': (
                  scope.row.taskStatus == 6? 'danger': 'info'
                )
              )
            )
          "
        >
          {{ statusMap[scope.row.taskStatus] }}
        </el-tag>
      </template>
      <!-- 飞手 -->
      <template slot="memberIds" slot-scope="{ row }">
        <span>
          {{
            memberList.filter(item=>{
              return row.memberIds.indexOf(item.value) > -1
            }).map(item=>{
              return item.label
            }).join($t('comma'))
          }}
        </span>
      </template>
      <!-- 载荷 -->
      <template slot="payloadId" slot-scope="{ row }">
        <span>{{ row.loadName || "-" }}</span>
      </template>
      <!-- 数据链 -->
      <template slot="dataLinkID" slot-scope="{ row }">
        <span>{{ row.datalinkName || "-" }}</span>
      </template>
      <!-- 报警 -->
      <template slot="alarmState" slot-scope="{ row }">
        <div class="abnormalCondition" v-if="row.alarmState">有</div>
        <div class="abnormalCondition-no" v-else>无</div>
      </template>
      <!-- 时间组件 -->
      <template slot="mixedTime" slot-scope="scope">
        <div>
          <div>
            <span>{{ scope.row.planBeginTime }} —— </span>
            <span>{{ scope.row.planFinishTime }}</span>
          </div>
          <div>
            <span>{{ scope.row.realBeginTime }} —— </span>
            <span>{{ scope.row.realFinishTime }}</span>
          </div>
        </div>
      </template>
      <!-- 操作栏 -->
      <template slot-scope="scope" slot="menu">
        <el-button
          v-if="arbitrament('view', scope.row)"
          type="text"
          icon="el-icon-view"
          size="small"
          @click="viewDetail(scope.row)"
        >
          查看
        </el-button>
        <el-button
          v-if="arbitrament('view', scope.row)"
          type="text"
          icon="el-icon-circle-close"
          size="small"
          @click="endTask(scope.row)"
        >
          结束任务
        </el-button>
        <el-button 
          v-if="arbitrament('videoReplay', scope.row)"
          type="text" icon="el-icon-video-camera-solid"
          size="small" @click="viewVideoDetail(scope.row)">
          视频回放
        </el-button> 
        <el-button
          v-if="arbitrament('trackReplay', scope.row)"
          type="text"
          icon="el-icon-discover"
          size="small"
          @click="viewDetail(scope.row)"
        >
          轨迹回放
        </el-button>
        <el-button
          v-if="arbitrament('trackReplay', scope.row)"
          type="text"
          icon="el-icon-download"
          size="small"
          @click="downLoadReport(scope.row)"
        >
          下载报告
        </el-button>
        <!-- 复制放在下个迭代 -->
        <!-- <el-button
          v-if="arbitrament('copy', scope.row)"
          type="text"
          icon="el-icon-document-copy"
          size="small"
          @click="copy(scope.row)"
        >
          复制
        </el-button> -->
        <el-button
          v-if="arbitrament('del', scope.row)"
          type="text"
          icon="el-icon-delete"
          size="small"
          @click="rowDel(scope.row)"
        >
          删除
        </el-button>
        
        <el-dropdown
          @command="onCopy"
          class="copy-btn"
          placement="bottom"
          v-if="arbitrament('view', scope.row)">
          <span>
            <el-button
              type="text"
              size="small"
              >
              复制推流地址
            </el-button>
            <i class="el-icon-arrow-down task-detail-drodown"></i>
          </span>
          <el-dropdown-menu 
            v-if="(scope.row.liveVideoVOList && Array.isArray(scope.row.liveVideoVOList))? true: false"
            slot="dropdown">
            <el-dropdown-item
              :command="[item, 'push']"
              v-for="item in (scope.row.liveVideoVOList? scope.row.liveVideoVOList: [])"
              :key="item.id">
              {{item.liveDevice}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown
          @command="onCopy"
          class="copy-btn"
          placement="bottom"
          v-if="arbitrament('view', scope.row)">
          <span>
            <el-button
              type="text"
              size="small"
              >
              复制播放地址
            </el-button>
            <i class="el-icon-arrow-down task-detail-drodown"></i>
          </span>
          <el-dropdown-menu 
            v-if="(scope.row.liveVideoVOList && Array.isArray(scope.row.liveVideoVOList))? true: false"
            slot="dropdown">
            <el-dropdown-item
              :command="[item, 'pull']"
              v-for="item in (scope.row.liveVideoVOList? scope.row.liveVideoVOList: [])"
              :key="item.id">
              {{item.liveDevice}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </avue-crud>
    <!-- 新增表单 -->
    <el-dialog
      :title="editAction? $t('addTask'): $t('copyTask')"
      :visible.sync="editDialogVisible"
      width="900px"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleCloseEdit">
      <el-form
        :model="editForm"
        :rules="rules"
        ref="editForm"
        label-width="auto"
        inline
        class="demo-ruleForm">
        <!-- 任务名称 -->
        <el-form-item
          :label="`${$t('mission.name')}：`"
          prop="taskName">
          <el-input
            style="width: 248px"
            v-model="editForm.taskName"
            size="small"
            :placeholder="$t('inputPlease')"
          >
          </el-input>
        </el-form-item>
        <!-- 执行人 -->
        <el-form-item 
            :label="`${$t('executor')}：`"
            prop="memberIds">
          <select-tree
            v-model="editForm.memberIds"
            :data="memberList"
            size="small"
            :multiple="true"
            :clearable="true"
            style="width: 248px"
            :placeholder="$t('selectPlease')"
          >
          </select-tree>
        </el-form-item>
        <!-- 所属部门 -->
        <el-form-item 
            :label="`${$t('department')}：`"
            prop="teamId">
          <select-tree
            v-model="editForm.teamId"
            :data="teamList"
            size="small"
            :clearable="true"
            style="width: 248px"
            :placeholder="$t('selectPlease')"
          >
          </select-tree>
        </el-form-item>
        <!-- 执行时间 -->
        <el-form-item
          :label="`${$t('mission.executionTime')}：`"
          prop="executionTime">
          <el-date-picker
            v-model="editForm.executionTime"
            size="small"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 396px;"
            type="datetimerange"
            :range-separator="$t('to')"
            :start-placeholder="$t('startTime')"
            :end-placeholder="$t('endTime')"
            :default-time="['00:00:00', '23:59:59']"
            :placeholder="$t('selectPlease')">
          </el-date-picker>
        </el-form-item>
        <!-- 使用飞机 -->
        <div></div>
        <el-form-item 
          :label="`${$t('mission.usingUAV')}：`"
          prop="airPlaneID">
          <select-tree
            v-model="editForm.airPlaneID"
            :data="airPlaneList"
            size="small"
            :clearable="true"
            style="width: 248px"
            :placeholder="$t('selectPlease')"
          >
          </select-tree>
          <el-button
            style="margin-left: 4px"
            type="primary"
            size="small"
            plain
            @click="chooseEquip('uav')">
            {{$t('mission.selectEquipment')}}
          </el-button>
        </el-form-item>
        <!-- 使用载荷-->
        <div></div>
        <el-form-item 
          :label="`${$t('mission.usingPod')}：`">
          <select-tree
            v-model="editForm.loadID"
            :data="loadList"
            size="small"
            :clearable="true"
            style="width: 248px"
            :placeholder="$t('selectPlease')"
          />
          <el-button
            style="margin-left: 4px"
            type="primary"
            size="small"
            plain
            @click="chooseEquip('pod')">
            {{$t('mission.selectEquipment')}}
          </el-button>
        </el-form-item>
        <!-- 使用数据链 -->
        <div></div>
        <el-form-item 
          :label="`${$t('mission.usingDataLink')}：`">
          <select-tree
            v-model="editForm.dataLinkID"
            :data="dataLinkList"
            size="small"
            :clearable="true"
            style="width: 248px"
            :placeholder="$t('selectPlease')"
          />
          <el-button
            style="margin-left: 4px"
            type="primary"
            size="small"
            plain
            @click="chooseEquip('datalink')">
            {{$t('mission.selectEquipment')}}
          </el-button>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="handleCloseEdit">{{
          $t("btnCancel")
        }}</el-button>
        <el-button
          size="small"
          v-loading="submitLoading"
          type="primary"
          @click="submitTask"
          >{{ $t("btnOk") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 设备列表 -->
    <el-dialog
      title="设备列表"
      :visible.sync="equipmentDialogVisible"
      width="950px"
      class="equip-dialog-001"
      :append-to-body="true"
      :before-close="closeEquipmentDialog"
      :modal="true"
      destroy-on-close
    >
      <FormLayer
        ref="FormLayer"
        :equipType="equipType"
        :projectId="projectId"
        :teamId="form.teamId"
        v-if="equipmentDialogVisible"
        :column="equipColumn"
        @changeSelections="changeSelections"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="equipmentDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="selectEquip">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 轨迹回放 -->
    <DetailDialog
      v-if="trackDetailVisible"
      :dialogData="dialogData"
      :dialogVisible="trackDetailVisible"
      @closeDialog="trackCloseDetail"
    />
    <!-- 视频回放 -->
    <VideoDialog
      v-if="videoHistoryDialogVisible"
      :taskPlayBackData="taskPlayBackData"
      :videoDialogData="videoDialogData"
      :videoDialogVisible="videoHistoryDialogVisible"
      @videoCloseDialog="videoCloseDialog"

    />
  </basic-container>
</template>

<script>
import {
  getList,
  getDetail,
  add,
  update,
  getUsers,
  remove,
  getFlyList,
  assign,
  getHistoryVideo,
  endTask
} from "@/api/phugiacloud/projectTask";
import { getUseEquipmentList } from "@/api/phugiacloud/projectManagement";
import { download } from "@/api/alarm/identify-data";
import { getList as  getDeptList } from '@/api/system/dept';
import { detail } from "@/api/phugiacloud/operatorTask";
import { mapGetters } from "vuex";
import { getToken } from "@/util/auth";
import { exportExcel } from "@/util/export";
import website from "@/config/website";
import FormLayer from "@/components/form-layer/index";
import DetailDialog from "@/components/task-detail-dialog";
import VideoDialog from "./components/task-video-dialog";

export default {
  name: "projecTask",
  components: {
    FormLayer,
    DetailDialog,
    VideoDialog,
  },
  data() {
    return {
      // 编辑状态
      editAction: 'add', 
      // 任务类型列表
      taskTypeList: [
        {
          label: "飞行任务",
          value: 1,
        },
        {
          label: "机库任务",
          value: 2,
        },
      ],
      // 新增表单弹窗显示
      editDialogVisible: false,
      // 表单form
      editForm: {
        taskName: '',
        memberIds: [],
        teamId: '',
        executionTime: [],
        airPlaneID: '',
        loadID: '',
        dataLinkID: ''
      },
      // 效验规则
      rules: {
        taskName: [
          { required: true, message: this.$t("inputPlease"), trigger: "blur" },
        ],
        memberIds: [
          { required: true, message: this.$t("selectPlease"), trigger: "blur" },
        ],
        teamId: [
          { required: true, message: this.$t("selectPlease"), trigger: "blur" },
        ],
        executionTime: [
          { required: true, message: this.$t("selectPlease"), trigger: "blur" },
        ],
        airPlaneID: [
          { required: true, message: this.$t("selectPlease"), trigger: "blur" },
        ]
      },
      // 提交中
      submitLoading: false,
      // 执行人
      memberList: [],
      // 部门列表
      teamList: [],
      // 勾选设备
      airPlaneIDList: [],
      // 视频回弹窗显示
      videoHistoryDialogVisible: false,
      // 轨迹回弹窗显示
      trackDetailVisible: false,
      // 无人机回显列表
      airPlaneList: [],
      // 载荷回显列表
      loadList: [],
      // 无数据链回显列表
      dataLinkList: [],
      // 状态字典
      statusMap: {
        1: "待执行",
        2: "已领取",
        3: "执行中",
        4: "已完成",
        7: '已过期'
      },
      // 查看设备弹窗---设备类型
      equipType: "",
      // 视屏回放当前行数据
      taskPlayBackData: {},
      // 请求头信息
      headers: {
        "Blade-Auth": "bearer " + getToken(),
        Authorization: `Basic ${Base64.encode(
          `${website.clientId}:${website.clientSecret}`
        )}`,
      },
      // 
      form: {
        projectId: null,
        teamId: null,
        equipmentIds: [],
        equipmentNames: [],
        timeRange: [],
        taskTimeRange: [],
      },
      flyForm: {
        projectId: this.projectId,
        operatorIds: "",
      },
      equipFormNames: {
        uav: "",
        pod: "",
        datalink: "",
      },
      equipForm: {
        uav: "",
        pod: "",
        datalink: "",
      },
      query: {},
      flyOptions: [],
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      equipPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      dialogData: {},
      // 
      routeDialog: false,
      // 设备列表
      equipmentDialogVisible: false,
      routesVisible: false,
      videoDialogVisible: false,
      flyDialog: false,
      flyFormRule: {
        taskName: [
          { required: true, message: "请填写任务名", trigger: "blur" },
        ],
        taskType: [
          { required: true, message: "请选择任务类型", trigger: "blur" },
        ],
        groundStationType: [
          { required: true, message: "请填写使用地面站", trigger: "blur" },
        ],
        planBeginTime: [
          {
            required: true,
            message: "请选择开始时间以及计划结束时间",
            trigger: "blur",
          },
        ],
        planFinishTime: [
          { required: true, message: "请选择结束时间", trigger: "blur" },
        ],
        operatorIds: [
          { required: true, message: "请指派飞手", trigger: "blur" },
        ],
      },
      equipColumn: [
        {
          label: "设备序列号",
          prop: "serialNumber",
          search: true,
          display: false,
          rules: [
            {
              required: true,
              message: "请输入序列号",
              trigger: "blur",
            },
          ],
        },
        {
          label: "设备名称",
          prop: "equipmentName",
          search: true,
          display: false,
          rules: [
            {
              required: true,
              message: "请输入设备名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "设备类型",
          prop: "equipmentType",
          dicUrl:
            "/api/blade-system/dict/dictionary-tree?code=equipment_type",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          type: "tree",
          display: false,
          search: true,
          multiple: true,
          dicFormatter: (res) => {
            if (res.data) {
              for (let i in res.data) {
                if (res.data[i].children.length !== 0) {
                  res.data[i].disabled = true;
                }
              }
              return res.data;
            } else {
              return [];
            }
          },
          rules: [
            {
              required: true,
              message: "请选择设备类型",
              trigger: "blur",
            },
          ],
        }
      ],
      routeColumn: [
        {
          label: "任务编号",
          prop: "id",
          search: true,
        },
        {
          label: "任务名称",
          prop: "taskName",
          search: true,
        },
        {
          label: "任务类型",
          prop: "taskTypeDesc",
        },
      ],
      selectionList: [],
      data: [],
      equipSelections: [],
      routeSelections: {},
      siteOptions: []
    };
  },
  computed: {
    ...mapGetters([
      "permission"
    ]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.projectTask_add, false),
        viewBtn: this.vaildData(this.permission.projectTask_view, false),
        delBtn: this.vaildData(this.permission.projectTask_delete, false),
        editBtn: this.vaildData(this.permission.projectTask_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    projectId() {
      return this.$route.query.projectId;
    },
    teamId() {
      return this.$route.query.teamId;
    },
    option() {
      return {
        height: "auto",
        calcHeight: 110,
        tip: false,
        searchShow: true,
        searchMenuSpan: 4,
        border: true,
        index: true,
        viewBtn: false, 
        selection: true,
        dialogClickModal: false,
        excelBtn: false,
        columnBtn: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        cellBtn: false,
        excelBtn: false,
        labelWidth: 110,
        menuWidth: 240,
        menuFixed: "right",
        searchBtnText: this.$t('searchBtn'),
        column: [
          // 任务名称
          {
            label: "任务名称",
            prop: "taskName",
            searchSpan: 5,
            search: true,
            rules: [
              {
                required: true,
                message: "请输入任务名称",
                trigger: "blur",
              },
            ],
          },
          // 任务类型
          {
            label: "任务类型",
            prop: "taskType",
            width: 80,
            addDisplay: false,
            editDisplay: false,
            formatter: (row, value, label) => {
              var node = this.taskTypeList.find((item) => {
                return item.value === value;
              });
              return node && node.label ? node.label : "--";
            },
          },
          // 飞行器
          {
            display: false,
            label: "飞行器",
            prop: "uavId",
            width: 100,
            slot: true,
          },
          // 执行状态（搜索）
          {
            label: "执行状态",
            prop: "taskStatus",
            searchSpan: 5,
            search: true,
            hide: true,
            addDisplay: false,
            editDisplay: false,
            type: "select",
            dicData: Object.keys(this.statusMap).map(item => {
              return{ label: this.statusMap[item], value: item }
            }),
            showColumn: false,
            rules: [
              {
                required: true,
                message: "请选择项目状态",
                trigger: "blur",
              },
            ],
          },
           // 执行状态
          {
            label: "执行状态",
            prop: "taskStatusName",
            width: 80,
          },
          // 计划/实际执行时间
          {
            label: "计划/实际执行时间",
            prop: "mixedTime",
            span: 24,
            width: 280,
            display: false,
          },
          // 实际飞行时长
          {
            label: "实际飞行时长",
            prop: "flyTime",
            searchSpan: 10,
            width: 120,
            display: false,
            formatter:(val,value,label)=>{
              var hours = Math.floor(value / 3600);
              var minutes = Math.floor((value - (hours * 3600)) / 60);
              var remainingSeconds = value - (hours * 3600) - (minutes * 60);

              if (hours < 10) {
                hours = "0" + hours;
              }

              if (minutes < 10) {
                minutes = "0" + minutes;
              }

              if (remainingSeconds < 10) {
                remainingSeconds = "0" + remainingSeconds;
              }

              return hours + "h" + minutes + "m" + remainingSeconds+"s";

            }
          },
          // 实际飞行里程
          {
            label: "实际飞行里程",
            prop: "flyDistance",
            searchSpan: 10,
            display: false,
            formatter:(val,value,label)=>{
              if (!value) return "0km";
              return (value/ 1000).toFixed(2) + "km";
            }
          },
          // 有无报警
          {
            prop: "alarmState",
            label: "有无报警",
            slot: true,
            search: false,
            display: false,
          },
          // 飞手
          {
            label: "飞手",
            prop: "memberIds",
            width: 120
          },
          // 所属团体
          {
            label: "所属团体",
            width: 120,
            prop: "teamId",
            type: "tree",
            dataType: "number",
            dicUrl: "/api/blade-system/dept/tree",
            props: {
              label: "title",
              value: "id",
            },
            rules: [
              {
                required: true,
                message: "请选择任务所属团体",
                trigger: "blur",
              },
            ],
          },
          // 载荷
          {
            display: false,
            width: 120,
            label: "载荷",
            prop: "payloadId",
            slot: true,
          },
          // 数据链
          {
            label: "数据链",
            width: 120,
            display: false,
            prop: "dataLinkID",
            slot: true,
          },
          // 派发平台
          {
            label: "派发平台",
            width: 120,
            prop: "assignPlatformName",
          },
          // 任务编号
          {
            label: "任务编号",
            prop: "id",
            width: 180,
            display: false,
          },
          // 使用设备(表单)
          {
            label: "使用设备",
            prop: "equipmentIds",
            formslot: true,
            hide: true,
            rules: [
              {
                required: true,
                message: "请选择使用设备",
                trigger: "blur",
              },
            ],
          }
        ],
      };
    }
  },
  mounted() {
    this.getUsers()
    this.getUseEquipmentList()
    this.getDeptList()
    this.$EventBus.$off('mission-canter-taskpage')
    this.$EventBus.$on('mission-canter-taskpage',(value)=>{
      if (value && Array.isArray(value)) {
        this.airPlaneIDList = value
        this.onLoad(this.page)
      }
    })
  },
  methods: {
    // 任务新增
    addTask() {
      this.editAction = 'add'
      this.editDialogVisible = true
    },
    // 新增提交
    submitTask() {
      this.$refs.editForm.validate((boolean)=>{
        if (boolean) {
          switch (this.editAction) {
            case 'add':
              this.submitLoading = true
              var data = {
                ...this.editForm,
                projectId: this.projectId,
                taskType: 1,
                planBeginTime: this.editForm.executionTime[0],
                planFinishTime: this.editForm.executionTime[1]
              }
              delete data.executionTime
              add(data).then(res=>{
                if (res.data.code === 200) {
                  this.$message.success(res.data.msg)
                  this.onLoad(this.page)
                  this.handleCloseEdit()
                }
              }).finally(()=>{
                this.submitLoading = false
              })
              break;
            case 'copy':
              console.log(this.editForm);
              // this.submitLoading = true
              // var data = {
              //   ...this.editForm,
              //   projectId: this.projectId,
              //   taskType: 1,
              //   planBeginTime: this.editForm.executionTime[0],
              //   planFinishTime: this.editForm.executionTime[1]
              // }
              // delete data.executionTime
              // add(data).then(res=>{
              //   if (res.data.code === 200) {
              //     this.$message.success(res.data.msg)
              //     this.onLoad(this.page)
              //     this.handleCloseEdit()
              //   }
              // }).finally(()=>{
              //   this.submitLoading = false
              // })
              break;
            default:
              break;
          }
          
        }
      })
    },
    // 关闭编辑任务表单
    handleCloseEdit() {
      this.$refs.editForm.resetFields()
      this.editForm = {
        taskName: '',
        memberIds: '',
        teamId:  '',
        executionTime:  [],
        airPlaneID: '',
        loadID:  '',
        dataLinkID:  ''
      }
      this.editDialogVisible = false
    },
    // 获取设备列表
    getUseEquipmentList() {
      getUseEquipmentList({ 
        projectId: this.projectId,
        equipmentType: 'uav',
        page: 9999
      }).then((res) => {
        const data = res.data.data;
        this.airPlaneList = data.map(item=>{
          return {
            value: item.id,
            label: item.equipmentName
          }
        })
      })
      getUseEquipmentList({ 
        projectId: this.projectId,
        equipmentType: 'pod',
        page: 9999
      }).then((res) => {
        const data = res.data.data;
        this.loadList = data.map(item=>{
          return {
            value: item.id,
            label: item.equipmentName
          }
        })
      })
      getUseEquipmentList({ 
        projectId: this.projectId,
        equipmentType: 'datalink',
        page: 9999
      }).then((res) => {
        const data = res.data.data;
        this.dataLinkList = data.map(item=>{
          return {
            value: item.id,
            label: item.equipmentName
          }
        })
      })
    },
    // 获取部门列表
    getDeptList() {
      getDeptList().then(res=>{
        if (res.data.code === 200) {
          this.teamList = this.filterDeptTree(res.data.data)
        }
      })
    },
    // 部门树数据过滤
    filterDeptTree(tree) {
      return tree.map((item) => {
        item.label = item.deptName;
        item.value = item.id;
        if (item.children && Array.isArray(item.children)) {
          item.children = this.filterDeptTree(item.children);
        }
        return item;
      });
    },
    // 复制地址
    onCopy(arr) {
      let [ node, type] = arr
      const text = type === 'push'? node.pushStreamUrl: node.webrtcPullStreamUrl
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
          .then(() => {
            this.$message.success('复制成功');
          })
          .catch(() => {
            this.$message.error('复制失败');
          });
      } else {
        // IE
        try {
          var textarea = document.createElement('textarea');
          textarea.style.position = 'fixed';
          textarea.style.opacity = 0;
          textarea.value = text;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand('copy');
          document.body.removeChild(textarea);
          this.$message.success('复制成功');
        } catch (error) {
          this.$message.error('复制失败，建议使用chrome浏览器');
        }
      }
    },
    copy(row) {
      this.editAction = 'copy'
      this.editDialogVisible = true
      this.editForm = {
        taskName: row.taskName + '（复制）',
        memberIds: row.memberIds,
        teamId:  row.teamId,
        executionTime:  [],
        airPlaneID:  row.airPlaneID,
        loadID:  row.loadID,
        dataLinkID:  row.dataLinkID
      }
    },
    // 操作栏显示
    arbitrament(type, row) {
      /*
        状态：待执行，操作按钮：复制、删除；
        状态：已领取，操作按钮：查看、复制；
        状态：执行中，操作按钮：查看、复制、结束任务；
        状态：已完成，操作按钮：任务回放（视频回放、轨迹回放）、下载报告、复制、删除；
        状态：已过期，操作按钮：复制、删除；
      */
      const { taskStatus } = row;
      const btnMap = {
        1: { del: true, copy: true }, // 未执行
        2: { view: true, copy: true }, // 已领取
        3: { 
          view: true,
          endTask: true,
          stream: true,
          copy: true
        }, // 执行中
        4: {
          trackReplay: true,
          videoReplay: true,
          del: true,
          download: true,
          copy: true
        }, // 已完成
        7: { del: true , copy: true  } // 已过期
      };
      if (btnMap[taskStatus]) {
        return btnMap[taskStatus][type] ? true : false;
      }
      return false;
    },
    // 搜索时间
    dateChange(val) {
      if (val) {
        const params = {
          ...this.query,
          startTime: val[0],
          endTime: val[1],
        };
        this.onLoad(this.page, params);
      }
    },
    // 获取执行任务人
    getUsers() {
      getUsers().then((res) => {
        this.memberList = res.data.data.map(item=>{
          return {
            value: item.id,
            label: item.realName
          }
        });
      });
    },
    // 导出功能
    handleExportExcel() {
      const option = {
        title: "飞行任务列表",
        columns: [
          {
            label: "任务编号",
            prop: "id",
          },
          {
            label: "任务名称",
            prop: "taskName",
          },
          {
            label: "任务类型",
            prop: "taskTypeDesc",
          },
          {
            label: "行业类型",
            prop: "$industryType",
          },
          {
            label: "使用地面站",
            prop: "$groundStationType",
          },
          {
            label: "航线名称",
            prop: "taskRouteName",
          },
          {
            label: "飞手",
            prop: "flyOperatorName",
          },
          {
            label: "任务所属团队",
            prop: "$teamId",
          },
          {
            label: "飞行器",
            prop: "airPlaneName",
          },
          {
            label: "载荷",
            prop: "loadName",
          },
          {
            label: "数据链",
            prop: "datalinkName",
          },
          {
            label: "执行状态",
            prop: "taskStatusName",
          },
          {
            label: "计划开始时间",
            prop: "planBeginTime",
          },
          {
            label: "计划结束时间",
            prop: "planFinishTime",
          },
        ],
      };
      const _this = this;
      exportExcel(option, _this);
    },
    // 指派任务
    giveWork(row) {
      this.flyForm.operatorIds = "";
      this.flyForm = row;
      if (row.operatorIds instanceof Array) {
        this.flyForm.operatorIds = row.operatorIds[0];
      } else {
        this.flyForm.operatorIds = row.operatorIds;
      }
      getFlyList().then((res) => {
        if (res.data.code === 200) {
          const data = res.data.data.records;
          this.flyOptions = [];
          data.map((item) => {
            this.flyOptions.push({
              label: item.realName,
              value: item.userId,
            });
          });
        }
      });
      this.flyDialog = true;
    },
    // 视频回放
    viewVideoDetail(row) {
      getHistoryVideo(row.id).then((res) => {
        this.taskPlayBackData = row;
        this.videoDialogData = res.data.data
        if (res.data.code == 200) {
          if (this.hasVideoURL(this.videoDialogData)) {
            this.videoHistoryDialogVisible = true;
            return
          }
          if (!this.videoHistoryDialogVisible) {
            this.$message({
              message: "此任务暂无视频回放，请核实",
              type: "warning",
            });
          }
        } else {
          this.$message({
            message: "此任务暂无视频回放，请核实",
            type: "warning",
          });
        }
      });
    },
    hasVideoURL(data) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (Array.isArray(item.historyVideoVOList)) {
          let urlList = item.historyVideoVOList
          for (let j = 0; j < urlList.length; j++) {
            return true
          }
        }
      }
      return false
    },
    // 确认指派
    taskflyOperate() {
      this.$refs["flyForm"].validate((valid) => {
        if (valid) {
          const data = {
            operatorIds: [this.flyForm.operatorIds],
            taskId: this.flyForm.id,
          };
          assign(data)
            .then((res) => {
              if (res.data.code === 200) {
                this.$message.success("任务指派成功！");
                this.flyDialog = false;
              }
            })
            .then(() => {
              this.onLoad(this.page, this.query);
            });
        } else {
          return false;
        }
      });
    },
    routeFormInit() {
      this.routeForm = {
        historicalAreaId: "",
        routeId: "",
        areaLink: "",
        historicalLink: "",
        // selectAreaId: "",
        routeId: "",
      };
    },
    equipInfoInit() {
      this.equipFormNames = {
        uav: "",
        pod: "",
        datalink: "",
      };
      this.equipForm = {
        uav: "",
        pod: "",
        datalink: "",
      };
    },
    // 清空历史航线
    clearHistoryRoutes() {
      this.routeForm.historicalAreaId = null;
    },
    // 选择历史航线
    chooseHisRoute() {
      if (this.routeForm.routeId) {
        this.$message.warning("历史航线与上传航线二者选填一，请核对！");
        return false;
      } else {
        this.routeDialog = true;
        this.routeForm.historicalAreaId = "";
        this.routeForm.historicalLink = "";
        this.routeForm.historicalRouteId = "";
        this.routeSelections = "";
      }
    },
    handleRemoveArea(file, fileList) {
      // this.routeForm.selectAreaId = null;
      this.routeForm.areaLink = null;
    },
    handleRemoveRoute(file, fileList) {
      this.routeForm.routeId = null;
      this.routeForm.routeLink = null;
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    changeSelections(params) {
      const type = params.equipType;
      if (params.selectionList.length) {
        const list = params.selectionList;
        this.equipForm[type] = list[0].id;
        this.equipFormNames[type] = list[0].equipmentName;
      } else {
        this.equipForm[type] = "";
        this.equipFormNames[type] = "";
      }
    },
    changeRouteSelections(list) {
      if (list.length === 0) {
        this.routeSelections = {};
      } else {
        this.routeSelections = list;
      }
    },
    selectEquip() {
      const selection = this.$refs.FormLayer.getSelection()
      if (selection.length === 0) {
        this.$message.warning("请勾选一条数据")
      } else if (selection.length === 1) {
        switch (this.equipType) {
          case 'uav':
            this.editForm.airPlaneID = selection[0].id
            break;
          case 'pod':
            this.editForm.loadID = selection[0].id
            break;
          case 'datalink':
            this.editForm.dataLinkID = selection[0].id
            break;
          default:
            break;
        }
        this.equipmentDialogVisible = false;
      } else {
        this.$message.warning("只能选择一条数据")
      }
    },
    // 下载报告
    downLoadReport(row) {
      this.$confirm("是否下载该报告?", "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        download(row.id).then((r) => {
          let index = r.headers["content-disposition"].indexOf("=") + 1;
          let fileName = decodeURIComponent(
            r.headers["content-disposition"].substr(index)
          );
          const blob = r.data;
          let link = document.createElement("a");
          link.href = URL.createObjectURL(
            new Blob([blob], {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            })
          );
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
        });
      });
    },
    // 
    chooseEquip(type) {
      this.equipType = type;
      this.equipmentDialogVisible = true;
    },
    // 查看详情
    viewDetail(row, status) {
      const params = {
        id: row.id,
      };
      detail(params).then((res) => {
        if (res.data.code === 200) {
          this.dialogData = res.data.data
          this.trackDetailVisible = true;
        }
      });
      // done()
    },
    // 结束任务
    endTask(row) {
      let params = {
        id: row.id
      }
      console.log(params);
      endTask(params).then(res=>{
        if (res.data.code === 200) {
          this.$message.success('任务已结束')
          this.onLoad(this.page);
        }
      })
    },
    closeEquipmentDialog(done) {
      this.equipmentDialogVisible = false;
      done();
    },
    trackCloseDetail() {
      const  { from } = this.$route.query;
      this.trackDetailVisible = false;
      if (from === 'detail') {
        // 跳转路由
        this.$router.push('/mission-center/item-manager-config')
        // 通知首页切换到项目列表
        this.$EventBus.$emit('toProjectList')
      }
      
    },
    videoCloseDialog(done) {
      this.videoHistoryDialogVisible = false;
      done();
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },
    async beforeOpen(done, type) {
      done();
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      params.projectId = this.projectId;
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done()
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      this.query.projectId = this.projectId;
      params.taskBackgroundRequest=true;
      params.taskType = 1;
      params.airPlaneIDList = this.airPlaneIDList.join(',')
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
        this.$nextTick(() => {
          this.$refs.crud.doLayout();
        });
      });
    },
  },
};
</script>

<style lang="scss">
.project-task-box-01 {
  .route-input {
    position: relative;

    &:hover {
      .route-input--delete {
        visibility: visible;
      }

      .route-input--deletehis {
        visibility: visible;
      }
    }

    &--delete {
      visibility: hidden;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      z-index: 9;
    }

    &--deletehis {
      visibility: hidden;
      position: absolute;
      top: 14px;
      right: 10px;
      cursor: pointer;
      z-index: 9;
    }
  }
  .snapshoot {
    min-height: 200px;
    max-height: 580px;
    overflow: scroll;

    & > img {
      width: 100%;
      display: block;
    }

    .avue-empty__image {
      display: flex;
      justify-content: center;
    }
  }
  .abnormalCondition {
    text-align: center;
    width: 55px;
    height: 24px;
    border-radius: 12px;
    color: red;
    background-color: rgba(255, 0, 0, 0.3);
    margin: 0 auto;
  }
  .abnormalCondition-no {
    text-align: center;
    width: 55px;
    height: 24px;
    border-radius: 12px;
    color: gray;
    background-color: lightgrey;
    margin: 0 auto;
  }
  .copy-btn {
    margin-left: 5px;
    .task-detail-drodown {
      color: #409EFF;
    }
  }
}
</style>
