<template>
  <div class="container normal mission-center-box">
    <operationBar/>
    <CusiumMap class="mission-center-lastItem" v-show="showMap" />
    <taskList  class="mission-center-lastItem" v-if="$route.name==='project_task'"/>
  </div>
</template>

<script>
import CusiumMap from "@/components/cesium/index";
import taskList from "@/views/mission-center/item-manager-config/projectTaskTabs"
import operationBar from "./operationBar/";

export default {
  // 这里和后台的code字段对应，用于做路由缓存
  name: "project-manager",
  components: {
    CusiumMap,
    taskList,
    operationBar
  },
  data() {
    return {
      component: "map",
    };
  },
  computed: {
    showMap() {
      let secondLevel = this.$route.matched[1];
      return ['project_task'].indexOf(this.$route.name) === -1 &&
        secondLevel.name !== 'no-fly-zone' &&
        secondLevel.name !== 'airport-manager'
    }
  },
  mounted() {}
  
};
</script>

<style lang="scss">
.mission-center-box{
  width: 100%;
  height: 100%;
  display: flex;
  .mission-center-lastItem{
    flex-grow: 1;
    overflow: hidden;
  }
}
</style>
