var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "operation-bar",
      style: _vm.$route.name !== "project-manager" ? "padding-top: 49px" : "",
    },
    [
      _vm.$route.name === "project-manager"
        ? _c("ProjectList", {
            on: {
              editProject: _vm.editProject,
              detailProject: _vm.detailProject,
              addProject: _vm.addProjectToItem,
            },
          })
        : _vm._e(),
      _vm.showThirdMenu
        ? _c(
            "div",
            { staticClass: "detail-tabs" },
            [
              _vm._l(_vm.thirdMenu, function (item) {
                return _c(
                  "div",
                  {
                    key: item.path,
                    class:
                      _vm.$route.name === item.code
                        ? "detail-tabs-item detail-tabs-item--active"
                        : "detail-tabs-item",
                    on: {
                      click: function ($event) {
                        return _vm.changeActive(item)
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "detail-tabs-item--top" }, [
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src:
                            _vm.$route.name === item.code
                              ? "/img/project/" + item.code + "-active.png"
                              : "/img/project/" + item.code + ".png",
                          alt: "",
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "detail-tabs-item--bottom" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ]
                )
              }),
              _c(
                "div",
                { staticClass: "setting-end", on: { click: _vm.setting } },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "el-icon-setting",
                  }),
                  _c("span", { staticClass: "setting-end-txt" }, [
                    _vm._v("设置"),
                  ]),
                ]
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.thirdRouterViewer
        ? _c("router-view", { staticClass: "third-routing" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }