var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "hangar-route-dialog-2024-8-12",
      attrs: {
        title: _vm.$t("mission.missionRoute"),
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "append-to-body": "",
        width: "1014px",
        "before-close": _vm.handleRouteClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "hangar-route-box" }, [
        _c("div", { staticClass: "hangar-route-map-box" }, [
          _vm.dialogVisible
            ? _c("div", { attrs: { id: "cesiumContainer_hangar_route" } }, [
                _c(
                  "div",
                  { staticClass: "hangar-route-tabs" },
                  _vm._l(_vm.routeList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.value,
                        staticClass: "tabs-item",
                        class:
                          item.value === _vm.value ? "tabs-item-active" : "",
                        on: {
                          click: function ($event) {
                            return _vm.itemClick(item)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "tabs-item-checked" }, [
                          item.value === _vm.value
                            ? _c("i", { staticClass: "el-icon-check" })
                            : _vm._e(),
                        ]),
                        _c("span", { staticClass: "tabs-item-label" }, [
                          _vm._v(_vm._s(item.label)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "hangar-route-panel" }, [
                  _c("div", { staticClass: "route-panel-line" }, [
                    _c("div", [_vm._v("航线长度")]),
                    _c("div", [_vm._v("--")]),
                  ]),
                  _c("div", { staticClass: "route-panel-line" }, [
                    _c("div", [_vm._v("航点")]),
                    _c("div", [_vm._v("--")]),
                  ]),
                  _c("div", { staticClass: "route-panel-line" }, [
                    _c("div", [_vm._v("预计执行时间")]),
                    _c("div", [_vm._v("--")]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleRouteClose } },
            [_vm._v(_vm._s(_vm.$t("btnClose")))]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v(_vm._s(_vm.$t("btnOk")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }