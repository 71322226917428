<template>
  <el-dialog
    :title="$t('mission.MissionDetails')"
    :visible.sync="dialogVisible"
    class="task-replay-dialog-main"
    :fullscreen="true"
    :append-to-body="true"
    :before-close="closeDialog"
    destroy-on-close
    :modal="false">
    <div v-if="dialogVisible" class="task-fly">
      <div class="task-info">
        <div class="task-info-title">
          <i 
            style="cursor: pointer"
            class="el-icon-arrow-left"
            @click="closeDialog"
            >
          </i>
          <span>
            {{$t('mission.taskReplay')}}
          </span>
        </div>
        <div class="task-info-content">
          <div class="task-info-content-title">
            {{$t('mission.MissionDetails')}}
          </div>
          <div class="task-info-content-box">
              <div class="item-line">
                <span>{{$t('mission.name')}}:</span>
                <span>{{ dialogData.name }}</span>
              </div>
              <div class="item-line">
                <span>{{$t('mission.taskType')}}:</span>
                <span>{{ dialogData.taskType }}</span>
              </div>
              <div class="item-line">
                <span>{{$t('mission.aerocraft')}}:</span>
                <span>{{ dialogData.equipmentName }}</span>
              </div>
              <div class="item-line">
                <span>{{$t('startTime')}}:</span>
                <span>{{ dialogData.startTime }}</span>
              </div>
              <div class="item-line">
                <span>{{$t('endTime')}}:</span>
                <span>{{ dialogData.endTime }}</span>
              </div>
              <div class="item-line">
                <span>{{$t('mission.team')}}:</span>
                <span>{{ dialogData.deptNames }}</span>
              </div>
              <div class="item-line">
                <span>{{$t('mission.creator')}}:</span>
                <span>{{ dialogData.createUserRealName }}</span>
              </div>
              <div class="item-line">
                <span>{{$t('notes')}}:</span>
                <span>{{ dialogData.remark }}</span>
              </div>
          </div>
        </div>
      </div>
      <div class="task-map-box">
        <task-replay-map
          style="height: 100%"
          :videoList="videoList"
          :dialogData="dialogData">
        </task-replay-map>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import taskReplayMap from "@/components/map/task-replay-map";

export default {
  components: {
    taskReplayMap
  },
  data() {
    return {}
  },
  props: {
    dialogData: {
      type: Object,
      default() {
        return {
          taskName: "",
          teamName: "",
          taskTypeDesc: "",
          taskSite: "",
          equipmentName: "",
          flyOperatorName: "",
          planBeginTime: "",
          planFinishTime: "",
          taskAreaFileUrl: "",
          taskRouteUrl: "",
          taskStatus: "",
          id: "",
          videoUrl: "",
          projectName:"",
          projectId: ""
        };
      },
    },
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // 视频源地址
    videoList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss">
.task-replay-dialog-main {
  height: 100%;
  .is-fullscreen {
    background-color: #000;
    .el-dialog__header{
      display: none;
    }
    .el-dialog__body {
      padding: 10px 0px;
      height: 100%;
      box-sizing: border-box;
      background: #18181B;
      .task-fly{
        overflow: hidden;
        height: 100%;
        .task-info {
          width: 290px;
          height: 100%;
          display: inline-block;
          vertical-align: middle;
          .task-info-title{
            height: 70px;
            width: 100%;
            font-size: 16px;
            font-weight: bold;
            color: #FFFFFF;
            background: #1E222A;
            border-bottom: 2px #030303 solid;
            line-height: 20px;
            box-sizing: border-box;
            padding: 25px 23px;
            span {
              margin-left: 24px;
            }
            .right-relation {
              float: right;
            }
          }
          .task-info-content{
            height: calc(100% - 70px);
            background: #1E222A;
            overflow: auto;
            padding: 0px 16px 10px 16px;
            box-sizing: border-box;
            .task-info-content-title {
              padding-left: 11px;
              font-size: 14px;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 19px;
              margin-top: 24px;
            }
            .task-info-content-box {
              margin-top: 11px;
              background: #00000057;
              border: 1px solid #272729;
              padding: 3px 9px 10px 18px;
              .item-line {
                margin-top: 13px;
                font-size: 14px;
                font-family: MicrosoftYaHeiUI;
                color: #FFFFFF;
                line-height: 18px;
                display: flex;
                justify-content: space-between;
                :first-child{
                  opacity: 0.6;
                }
              }
            }
          }
        }
        .task-map-box {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% - 290px);
          height: 100%;
        }
      }
      
    }
  }
}
</style>
