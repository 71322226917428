<template>
  <basic-container class="project-task-box">
    <el-tabs v-model="activeName">
      <el-tab-pane
        :lazy="true"
        :label="$t('FilghtTask')"
        :name="$t('FilghtTask')">
        <task></task>
      </el-tab-pane>
      <el-tab-pane
        :lazy="true"
        :label="$t('mission.hangarTask')"
        :name="$t('mission.hangarTask')">
        <hangarTask></hangarTask>
      </el-tab-pane>
      <el-tab-pane
        :lazy="true"
        :label="$t('mission.taskRecord')"
        :name="$t('mission.taskRecord')">
        <record></record>
      </el-tab-pane>
    </el-tabs>
  </basic-container>
</template>

<script>
import task from './projectTask.vue'
import hangarTask from './hangarTask.vue'
import record from './recordTask.vue'

export default {
  components: { task, hangarTask, record },
  data() {
    return {
      activeName: this.$t('FilghtTask')
    }
  }
};
</script>

<style lang="scss">
  .project-task-box {
    border-radius: 0px !important;
    padding: 0px !important;
    background-color: #fff;
    .el-card.is-always-shadow {
      height: 100%;
      .el-card__body {
        height: 100%;
        box-sizing: border-box;
        .el-tabs {
          height: 100%;
          .el-tabs__content {
            height: calc(100% - 55px);
            .el-tab-pane {
              height: 100%;
            }
          }
        }
      }
    }
  }
</style>
