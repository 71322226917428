<template>
  <div class="operation-bar" :style="$route.name!=='project-manager'? `padding-top: 49px` : ``">
    <ProjectList
      v-if="$route.name==='project-manager'"
      @editProject="editProject"
      @detailProject="detailProject"
      @addProject="addProjectToItem"
    />
    <!-- 三级路由侧边栏 -->
    <div v-if="showThirdMenu" class="detail-tabs" >
      <div
        v-for="item in thirdMenu" 
        :key="item.path"
        :class="$route.name === item.code? 'detail-tabs-item detail-tabs-item--active': 'detail-tabs-item'"
        @click="changeActive(item)">
        <p class="detail-tabs-item--top">
          <img
            class="icon"
            :src="$route.name === item.code? `/img/project/${item.code}-active.png`: `/img/project/${item.code}.png`"
            alt=""
          />
        </p>
        <p class="detail-tabs-item--bottom">{{item.name}}</p>
      </div>
      <div
        @click="setting"
        class="setting-end"
      >
        <i v-loading="loading" class="el-icon-setting"></i>
        <span class="setting-end-txt">设置</span>
      </div>
    </div>
    <router-view class="third-routing" v-if="thirdRouterViewer"/>
  </div>
</template>

<script>
import ProjectList from "./projectList";
import { getProjectDetail } from "@/api/project/index";
import { mapGetters } from "vuex";

export default {
  name: "operationBar",
  components: {
    ProjectList
  },
  data() {
    return {
      projectId: null,
      detailItem: {
        userList: [],
        equipmentConfigList: [],
      },
      loading: false
    };
  },
  computed: {
    ...mapGetters([
      'permission',
      'menuAll'
    ]),
    // 三级菜单
    thirdMenu() {
      for (var i = 0; i < this.menuAll.length; i++) {
        const item = this.menuAll[i]
        if (item.code === 'mission-center') {
          if (item.children && Array.isArray(item.children)) {
            for (let j = 0; j < item.children.length; j++) {
              const element = item.children[j];
              if (element.code === 'project-manager') {
                if (element.children && Array.isArray(element.children) && element.children.length > 0) {
                  return element.children
                }
              }
            }
          }
        }
      }
      return []
    },
    showThirdMenu() {
      if (this.$route.name !== 'project-manager') {
        if (this.$route.query && this.$route.query.action) {
          return false
        } 
        return true
      }
      return false
    },
    thirdRouterViewer() {
      let secondLevel = this.$route.matched[1];
      return this.$route.name !== 'project-manager' && secondLevel.name !== 'no-fly-zone' 
        && secondLevel.name !== 'airport-manager' && this.$route.matched.length > 2
    }
  },
  methods: {
    editProject(item) {
      const params = {
        id: item.id,
      };
      getProjectDetail(params).then((res) => {
        if (res.data.code === 200) {
          this.detailItem = res.data.data;
          if (this.detailItem.centerPoint) {
            const arr = this.detailItem.centerPoint.split(',')
            this.$store.dispatch("setProjectPoint", arr)
          }
          this.$router.push({
            path: '/mission-center/item-manager-config/project_detail',
            query: {
              projectId: item.id,
              action: 'edit'
            }
          })
        }
      });
    },
    detailProject(item, moveTo) {
      let projectId = item.id
      let projectName = item.projectName
      const arr = item.centerPoint.split(',')
      if (moveTo && arr.length === 2) {
        this.$EventBus.$emit('map-flyTo', parseFloat(arr[0]), parseFloat(arr[1]))
      }
      for (var i = 0; i < this.menuAll.length; i++) {
        const item = this.menuAll[i]
        if (item.code === 'mission-center') {
          if (item.children && Array.isArray(item.children)) {
            for (let j = 0; j < item.children.length; j++) {
              const element = item.children[j];
              if (element.code === 'project-manager') {
                if (element.children && Array.isArray(element.children) && element.children.length > 0) {
                  this.$router.push({
                    path: element.children[0].path,
                    query: {
                      projectId,
                      projectName
                    }
                  })
                }
              }
            }
          }
        }
      }
    },
    addProjectToItem() {
      this.$store.dispatch("setProjectPoint", null);
      this.$router.push({
        path: '/mission-center/item-manager-config/project_detail',
        query: {
          action: 'add'
        }
      })
      // for (var i = 0; i < this.menuAll.length; i++) {
      //   const item = this.menuAll[i]
      //   if (item.code === 'mission-center') {
      //     if (item.children && Array.isArray(item.children)) {
      //       for (let j = 0; j < item.children.length; j++) {
      //         const element = item.children[j];
      //         if (element.code === 'project-manager') {
      //           if (element.children && Array.isArray(element.children) && element.children.length > 0) {
      //             this.$router.push({
      //               path: element.children[0].path,
      //               query: {
      //                 id,
      //                 projectId,
      //                 projectName
      //               }
      //             })
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    },
    changeActive(node) {
      let projectName = this.$route.query.projectName
      let projectId = this.$route.query.projectId
      this.$router.push({
        path: node.path,
        query: {
          projectId,
          projectName
        }
      })
    },
    setting() {
      if (this.$route.query.projectId) {
        const params = {
          id: this.$route.query.projectId,
        };
        getProjectDetail(params).then((res) => {
          if (res.data.code === 200) {
            this.detailItem = res.data.data;
            if (this.detailItem.centerPoint) {
              const arr = this.detailItem.centerPoint.split(',')
              this.$store.dispatch("setProjectPoint", arr)
            }
            if (this.$route.path === '/mission-center/item-manager-config/project_detail') {
              this.$router.push({
                path: `/mission-center/item-manager-config/project_detail?projectId=${this.$route.query.projectId}&action=edit`
              })
              this.$EventBus.$emit('mission-center-project-edit')
            } else {
              this.$router.push({
                path: '/mission-center/item-manager-config/project_detail',
                query: {
                  projectId: this.$route.query.projectId,
                  action: 'edit'
                }
              })
            }
          }
        });
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.operation-transition-leave-active {
  transition: opacity 0.2s;
}
.operation-transition-enter-active {
  transition: opacity 2.5s;
}
.operation-transition-enter,
.operation-transition-leave-to {
  opacity: 0;
}
.operation-bar {
  height: 100%;
  display: flex;
  background-color: rgba(30, 34, 42, 1);
  .detail-tabs {
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
    width: 55px;
    border-right: 1px solid #030303;
    border-top: 1px solid #030303;
    position: relative;
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px 0px;
      cursor: pointer;
      color: #76787d;
      font-size: 12px;
      position: relative;
      .icon {
        height: 20px;
      }
      &--active {
        font-weight: bold;
        // font-size: 14px;
        color: #3a76ff;
        &::after {
          position: absolute;
          content: "";
          width: 52px;
          height: 6px;
          background-image: url("/img/project/icon-active.png");
          background-size: cover;
          bottom: 0px;
          // left: 3px;
        }
      }
    }
    .detail-tabs-item--bottom {
      text-align: center;
    }
    .setting-end {
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: 20px;
      color: #FFFFFF;
      font-size: 20px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      cursor: pointer;
      .setting-end-txt {
        font-size: 12px;
        margin-top: 2px;
      }
    }
  }
  .third-routing {
    width: 290px;
    border-top: 1px solid #030303;
    height: calc(100% - 54px);
  }
}
</style>

